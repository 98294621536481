import React, {useEffect, useState} from 'react';
import EntityList from 'shared/entity/entity-list';
import {EntityConfiguration} from 'shared/entity/models';
import {CollectionQuery} from 'shared/model/collection.model';

import {useLazyListEmergencyReportQuery} from '../api/emergency-report-api-service';

function EmergencyReportList() {
    const [request, setRequest] = useState<CollectionQuery>({
        skip: 0,
        top: 10,
        orderBy: [
            {
                field: 'createdAt',
                direction: 'desc',
            },
        ],
        searchFrom: ['passenger_phone_number', 'driver_phone_number']
    });

    const [getData, result] = useLazyListEmergencyReportQuery();
    const [items, setItems] = useState(result?.data?.items);
    const [total, setTotal] = useState(result?.data?.total);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        getData(request, false);
    }, [request, getData]);

    useEffect(() => {
        setItems(result?.data?.items);
        setTotal(result?.data?.total);
        setLoading(result?.isLoading);
    }, [result]);

    let config: EntityConfiguration = {
        rootUrl: '/accepted-request',
        title: ' Emergency Reports',
        detailUrl: '',
        showNewButton: false,
        visibleColumn: [
            // {key: 'current_location', name: 'Current Location'},
            {key: ['trip', 'pickup_address'], name: 'Pickup Address'},
            {key: ['trip', 'drop_off_address'], name: 'Drop Of Address'},

            {
                key: ['trip', 'passenger', 'name'],
                name: 'Customer',
                postFix: {key: ['trip', 'passenger', 'phone_number']},
            },
            {
                key: ['trip', 'driver', 'first_name'],
                name: 'Provider',
                postFix: {key: ['trip', 'driver', 'phone_number']},
            },
            {key: 'reported_by', name: 'Reported By'},
            {key: 'created_at', name: 'Date', isDate: true},
        ],
        filter: [
            [
                {
                    field: 'reported_by',
                    value: 'Driver',
                    operator: '=',
                    fieldName: 'Reported By',
                    name: 'Provider',
                },
                {
                    field: 'reported_by',
                    value: 'Passenger',
                    operator: '=',
                    fieldName: 'Reported By',
                    name: 'Customer',
                },
            ],
        ],
    };

    const onSearch = (req: any) => {
        setRequest((prev) => {
            return {...prev, search: req};
        });
    };
    const onPagination = (req: any) => {
        setRequest((prev) => {
            return {...prev, skip: req.skip, top: req.top};
        });
    };
    const onFilter = (req: any) => {
        setRequest((prev) => {
            return {...prev, filter: req};
        });
    };

    return (
        <EntityList
            itemsLoading={loading}
            items={items}
            total={total}
            config={config}
            search={onSearch}
            pagination={onPagination}
            filter={onFilter}
        />
    );
}
export default EmergencyReportList;
