import {collectionQueryBuilder} from 'shared/model/collection-query-builder';
import {CollectionQuery} from 'shared/model/collection.model';
import {dashboardApiService} from 'store/dashboardApi';
import { DeliveryrRequestsEndpoint } from './delivery-requests-endpoint';
import MessagingServices from 'shared/messaging-services';
import {render} from '@testing-library/react';
import { DeliveryStatus } from '../pages/delivery-request-detail';


const DeliveryRequestsApiService = dashboardApiService.injectEndpoints({
    endpoints(builder) {
        return {
            listDeliveryRequests: builder.query({
                query: (query: CollectionQuery) => ({
                    url: `${DeliveryrRequestsEndpoint.fetch}`,
                    method: 'get',
                    headers: '',
                    params: collectionQueryBuilder(query),
                }),
                async onQueryStarted(id, {dispatch, queryFulfilled}) {
                    try {
                        const {data} = await queryFulfilled;
                      
                    } catch (error) {
                        
                    }
                },
            }),
            getDeliveryById: builder.query({
                query: (id) => ({
                    url: `${DeliveryrRequestsEndpoint.detail}/${id}`,
                    method: 'get',
                   
                }),
               
            }),

            
            updateDeliveryStatus: builder.mutation({
                query: (updatedData: any) => ({
                    url: `${DeliveryrRequestsEndpoint.updateStatus}/${updatedData.id}`,
                    method: 'post',
                    data: updatedData,
                }),
                async onQueryStarted(id, {dispatch, queryFulfilled}) {
                    try {
                        const {data} = await queryFulfilled;


                        render(
                            <MessagingServices
                                message={`Status updated successfully to ${data.status}`}
                                show={true}
                                type={'success'}
                            />,
                        );
                    } catch (error) {
                        render(
                            <MessagingServices
                                message={error.error.data.message}
                                show={true}
                                type={'error'}
                            />,
                        );
                    }
                },
            }),
            updateDeliveryCurrentLocation: builder.mutation({
                query: (updatedData: any) => ({
                    url: `${DeliveryrRequestsEndpoint.updateLocation}/${updatedData.id}`,
                    method: 'post',
                    data: updatedData,
                }),
                async onQueryStarted(id, {dispatch, queryFulfilled}) {
                    try {
                        const {data} = await queryFulfilled;


                        render(
                            <MessagingServices
                                message={`Delivery location successfully updated to ${data.current_location}`}
                                show={true}
                                type={'success'}
                            />,
                        );
                    } catch (error) {
                        render(
                            <MessagingServices
                                message={error.error.data.message}
                                show={true}
                                type={'error'}
                            />,
                        );
                    }
                },
            }),
            createDelivery: builder.mutation({
                query: (newDelivery: any) => ({
                    url: `${DeliveryrRequestsEndpoint.create}`,
                    method: 'post',
                    data: newDelivery,
                }),
                async onQueryStarted(id, {dispatch, queryFulfilled}) {
                    try {
                        const {data} = await queryFulfilled;

                        render(
                            <MessagingServices
                                message='Delivery Created Successfully'
                                show={true}
                                type={'success'}
                            />,
                        );
                    } catch (error) {
                        
                        render(
                            <MessagingServices
                                message={error.error.data.message}
                                show={true}
                                type={'error'}
                            />,
                        );
                    }
                },
            }),
            
        }
    }
});


export const {
    useUpdateDeliveryCurrentLocationMutation,
    useLazyListDeliveryRequestsQuery,
    // useGetDeliveryByIdQuery,
    useLazyGetDeliveryByIdQuery,
    useUpdateDeliveryStatusMutation,
    useCreateDeliveryMutation
    
    
} = DeliveryRequestsApiService;
