import * as yup from 'yup';

const phoneRegExp =
    /^([+][2][5][1]([7]|[9])[0-9]{8}$)|[+][2][5][1][9][0-9]{8}$/;

export const schema = yup
    .object({

        sender_name: yup.string().required('Sender name is required'),
        sender_phone_number: yup
            .string()
            .required('phone number is required')
            .matches(phoneRegExp, 'invalid phone number'),
        sender_email: yup.string().email(),
        sender_address: yup
            .object()
            .shape({
                country: yup.string().required('Country is required'),
                city: yup.string().required('City is required'),
                street_address: yup.string(),
                house_number: yup.string()
            })
            .required(),
            // sender_location: yup.string().required('Sender location required'),
        
        reciever_name: yup.string().required('Receiver name is required'),
       reciever_phone_number: yup
            .string()
            .required('phone number is required')
            .matches(phoneRegExp, 'invalid phone number'),
       reciever_email: yup.string().email(),
       reciever_address: yup
            .object()
            .shape({
                country: yup.string().required('Country is required'),
                city: yup.string().required('City is required'),
                street_address: yup.string(),
                house_number: yup.string()
            })
            .required(),
            //reciever_location: yup.string().required('Receiver location required'),

        delivery_type: yup.string().required('Delivery type is required'),
        delivery_summery: yup.string().required('Delivery summery required')

    })
    .required();
