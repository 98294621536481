export interface User {
    first_name: string;
    last_name: string;
    email: string;
    phone_number: string;
    user_type: string;
    roles: any[];
    emergency_contact: string;
    gender: string;
    is_active: boolean;
    enabled: boolean;
    address: Address;
}

export interface Address {
    city: string;
    sub_city: string;
    woreda: string;
    house_number: String;
}
export const UserTypes = [
    'Operator',
    'FinanceOfficer',
    'Admin',
    'SuperAdmin',
];
