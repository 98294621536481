export const baseUrl = 'https://ethiodeliverylogistics.com/backend/api/';
// export const baseUrl = 'http://localhost:5000/api/';
export const baseUrlForFile = 'https://ethiodeliverylogistics.com/backend';
export const firebaseApiKey = 'AIzaSyBW5NnM3KoGDZoXw7fklMgXU0Jhdh4z5PA';
export const firebaseDatabaseUrl = 'https://edl-389614-default-rtdb.firebaseio.com';
export const firebaseProjectId = "edl-389614";
export const firebaseAppId = "1:200288015979:android:8b796918a1639760cf032e";
export const googleMapApiKey = "AIzaSyCl9I3TEfV1PQx7k-6Jqc8VxEFfN1lcWY4";
export const  webSocketUrl = 'wss://ethiodeliverylogistics.com';
export const domain = "https://ethiodeliverylogistics.com/";

