import React, { useEffect, useState } from 'react'
import EntityList from 'shared/entity/entity-list'
import { EntityConfiguration } from 'shared/entity/models'
import { CollectionQuery } from 'shared/model/collection.model'
import { useLazyListPublicVehicleQuery } from '../api/public-vehicle-api-services'

function PublicVehicleList() {
  const [request, setRequest] = useState<CollectionQuery>({
    skip: 0,
    top: 10,
    includes: ['created_by', 'category'],
    orderBy: [
      {
        field: 'createdAt',
        direction: 'desc',
      },
    ],
    searchFrom: ['type', 'code', 'model', 'plate_number'],
  })

  const [getData, result] = useLazyListPublicVehicleQuery()
  const [items, setItems] = useState(result?.data?.items)
  const [total, setTotal] = useState(result?.data?.total)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    getData(request, false)
  }, [request, getData])
  useEffect(() => {
    setItems(result?.data?.items)
    setTotal(result?.data?.total)
    setLoading(result?.isLoading)
  }, [result])

  let config: EntityConfiguration = {
    rootUrl: 'public-vehicle',
    title: 'Vehicles List',
    detailUrl: 'public-vehicle-detail',
    showNewButton: false,
    visibleColumn: [
      { key: 'model', name: 'Model' },
      { key: 'code', name: 'Code' },
      { key: 'plate_number', name: 'Plate Number' },
      { key: 'type', name: 'Type' },
      {
        key: ['created_by', 'first_name'],
        name: 'Registered By',
        postFix: { key: ['created_by', 'last_name'] },
      },
      { key: 'created_at', name: 'Registered At', isDate: true },
      { key: 'enabled', name: 'Status', isBoolean: true },
    ],
    filter: [
      [
        {
          field: 'enabled',
          value: 'true',
          operator: '=',
          fieldName: 'Status',
          name: 'Active',
        },
        {
          field: 'enabled',
          value: 'false',
          operator: '=',
          name: 'Inactive',
          fieldName: 'Status',
        },
      ],
      [
        {
          field: 'type',
          value: 'taxi',
          operator: '=',
          fieldName: 'Car Type',
          name: 'Taxi',
        },
        {
          field: 'type',
          value: 'truck',
          operator: '=',
          name: 'Truck',
          fieldName: 'Car Type',
        },
      ],
      // [
      //   {
      //     field: 'category',
      //     value: 'FSR',
      //     operator: '=',
      //     fieldName: 'Car Category',
      //     name: 'FSR',
      //   },
      //   {
      //     field: 'category',
      //     value: 'Lada',
      //     operator: '=',
      //     fieldName: 'Car Category',
      //     name: 'Lada',
      //   },
      // ],
    ],
  }

  const onSearch = (req: any) => {
    setRequest((prev) => {
      return { ...prev, search: req }
    })
  }
  const onPagination = (req: any) => {
    setRequest((prev) => {
      return { ...prev, skip: req.skip, top: req.top }
    })
  }
  const onFilter = (req: any) => {
    setRequest((prev) => {
      return { ...prev, filter: req }
    })
  }

  return (
    <EntityList
      itemsLoading={loading}
      items={items}
      total={total}
      config={config}
      search={onSearch}
      pagination={onPagination}
      filter={onFilter}
    />
  )
}

export default PublicVehicleList
