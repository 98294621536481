import React, {useEffect, useState} from 'react';
import {Button, Card} from 'react-bootstrap';
import {useParams} from 'react-router-dom';
import EntityList from 'shared/entity/entity-list';
import {EntityConfiguration} from 'shared/entity/models';
import {CollectionQuery} from 'shared/model/collection.model';

import {useLazyListPassengerTripHistoryQuery} from '../api/passanger-api-service';

function PassengerTripHistory() {
    const {id} = useParams();
    const [isCollapsed, setIsCollapsed] = useState(true);

    const onCollapsed = () => {
        var isExpand = !isCollapsed;
        setIsCollapsed(isExpand);
    };

    const [request, setRequest] = useState<CollectionQuery>({
        skip: 0,
        top: 5,
        orderBy: [
            {
                field: 'createdAt',
                direction: 'desc',
            },
        ],
        searchFrom: ['driver_phone_number'], 
        includes: ['driver'],
    });

    const [getData, result] = useLazyListPassengerTripHistoryQuery();
    const [items, setItems] = useState(result?.data?.items);
    const [total, setTotal] = useState(result?.data?.total);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        getData({request, id}, false);
    }, [request, getData]);
    useEffect(() => {
        setItems(result?.data?.items);
        setTotal(result?.data?.total);
        setLoading(result?.isLoading);
    }, [result]);

    let config: EntityConfiguration = {
        rootUrl: 'public-driver',
        title: '',
        showNewButton: false,
        hasDetail: false,
        visibleColumn: [
            {key: 'pickup_address', name: 'Pickup Address'},
            {key: 'drop_off_address', name: 'Drop Off Address'},
            {
                key: ['fullName'],
                name: 'Driver',
                postFix: {key: ['driver', 'phone_number']},
            },
            {key: 'price', name: 'Price'},
            {key: 'net_price', name: 'Net Price'},

            {
                key: 'commission',
                name: 'Commission',
                // postFix: { key: ['updated_by', 'last_name'] },
            },
            {
                key: 'distance',
                name: 'Distance',
            },
            {key: 'starting_time', name: 'Starting Time', isDate: true},
            {key: 'completed_time', name: 'Completed Time', isDate: true},
            {key: 'status', name: 'Status', isBoolean: true},
        ],
        filter: [
            [
                {
                    field: 'enabled',
                    value: 'true',
                    operator: '=',
                    fieldName: 'Status',
                    name: 'Active',
                },
                {
                    field: 'enabled',
                    value: 'false',
                    operator: '=',
                    name: 'Inactive',
                    fieldName: 'Status',
                },
            ],
        ],
    };

    const onSearch = (req: any) => {
        setRequest((prev) => {
            return {...prev, search: req};
        });
    };
    const onPagination = (req: any) => {
        setRequest((prev) => {
            return {...prev, skip: req.skip, top: req.top};
        });
    };
    const onFilter = (req: any) => {
        setRequest((prev) => {
            return {...prev, filter: req};
        });
    };

    return (
        <Card className='mt-2'>
            <Card.Header className='d-flex justify-content-between'>
                <div>Passenger Trip List</div>

                <div className='d-flex gap-4'>
                    <Button size='sm' onClick={onCollapsed}>
                        {isCollapsed ? 'Expand' : 'Collapse'}
                    </Button>
                </div>
            </Card.Header>
            

            <Card.Body className={isCollapsed ? 'd-none' : ''}>
                <EntityList
                    itemsLoading={loading}
                    items={items}
                    total={total}
                    config={config}
                    search={onSearch}
                    pagination={onPagination}
                    filter={onFilter}
                />
            </Card.Body>
        </Card>
    );
}

export default PassengerTripHistory;
