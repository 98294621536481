import React, {useEffect, useState} from 'react';
import EntityList from 'shared/entity/entity-list';
import {EntityConfiguration} from 'shared/entity/models';
import {CollectionQuery} from 'shared/model/collection.model';
import {useLazyListBankCreditHistoryQuery} from '../api/bank-api-service';
function CreditHistory({driverId}) {
    const [request, setRequest] = useState<CollectionQuery>({
        skip: 0,
        top: 10,
        filter: [
            [
                {
                    field: 'driver',
                    value: driverId,
                    operator: '=',
                },
            ],
        ],
        orderBy: [
            {
                field: 'createdAt',
                direction: 'desc',
            },
        ],
    });
    const [getData, result] = useLazyListBankCreditHistoryQuery();
    const [items, setItems] = useState(result?.data?.items);
    const [total, setTotal] = useState(result?.data?.total);
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        getData(request, false);
    }, [request, getData]);
    useEffect(() => {
        setItems(result?.data?.items);
        setTotal(result?.data?.total);
        setLoading(result?.isLoading);
    }, [result]);
    let config: EntityConfiguration = {
        rootUrl: '/feedback',
        title: 'Transactions',
        hasDetail: false,
        detailUrl: '/feedback/detail',
        showNewButton: false,
        isTableCallapsedable: true,
        visibleColumn: [
            {key: 'created_at', name: 'Date', isDate: true},
            {key: 'payment_method', name: 'Payment Method'},
            {key: 'amount', name: 'Amount'},
        ],
        filter: [
            [
                {
                    field: 'payment_method',
                    value: 'Bank',
                    operator: '=',
                    fieldName: 'Payment Method',
                    name: 'Bank',
                },
                {
                    field: 'payment_method',
                    value: 'Credit Transfer',
                    operator: '=',
                    fieldName: 'Payment Method',
                    name: 'Credit Transfer',
                },
                {
                    field: 'payment_method',
                    value: 'Telebirr',
                    operator: '=',
                    fieldName: 'Payment Method',
                    name: 'Telebirr',
                },
            ],
        ],
    };
    const onSearch = (req: any) => {
        setRequest((prev) => {
            return {...prev, search: req, skip: 0, top: 10};
        });
    };
    const onPagination = (req: any) => {
        setRequest((prev) => {
            return {...prev, skip: req.skip, top: req.top};
        });
    };
    const onFilter = (req: any) => {
        setRequest((prev) => {
            return {
                ...prev,
                filter: [
                    ...req,
                    [
                        {
                            field: 'driver',
                            value: driverId,
                            operator: '=',
                        },
                    ],
                ],
                orderBy: [
                    {
                        field: 'createdAt',
                        direction: 'desc',
                    },
                ],
            };
        });
    };
    return (
        <EntityList
            itemsLoading={loading}
            items={items}
            total={total}
            config={config}
            search={onSearch}
            pagination={onPagination}
            filter={onFilter}
        />
    );
}
export default CreditHistory;
