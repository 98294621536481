import React, {useEffect, useState} from 'react';
import {Button, Card, Modal} from 'react-bootstrap';
import {useParams} from 'react-router-dom';
import EntityList from 'shared/entity/entity-list';
import {EntityConfiguration} from 'shared/entity/models';
import {CollectionQuery} from 'shared/model/collection.model';
import {useLazyGetAwardWinnersQuery} from '../api/award-api-service';
function AwardWinnersList() {
    const {id} = useParams();
    const [request, setRequest] = useState<CollectionQuery>({
        skip: 0,
        top: 10,
    });
    const [getData, result] = useLazyGetAwardWinnersQuery();
    const [items, setItems] = useState(result?.data?.items);
    const [total, setTotal] = useState(result?.data?.total);
    const [loading, setLoading] = useState(true);
    const [isCollapsed, setIsCollapsed] = useState(true);
    const onCollapsed = () => {
        var isExpand = !isCollapsed;
        setIsCollapsed(isExpand);
    };
    useEffect(() => {
        const sendData = {
            id,
            request,
        };
        getData(sendData, false);
    }, [request, getData, id]);
    useEffect(() => {
        setItems(result?.data?.items);
        setTotal(result?.data?.total);
        setLoading(result?.isLoading);
    }, [result]);
    let config: EntityConfiguration = {
        rootUrl: '/award',
        title: 'awards Winners List',
        detailUrl: '/award',
        showNewButton: false,
        hasDetail: false,
        visibleColumn: [
            {key: 'name', name: 'Full Name'},
            {key: 'title', name: 'Item'},
        ],
    };
    const onSearch = (req: any) => {
        setRequest((prev) => {
            return {...prev, search: req};
        });
    };
    const onPagination = (req: any) => {
        setRequest((prev) => {
            return {...prev, skip: req.skip, top: req.top};
        });
    };
    const onFilter = (req: any) => {
        setRequest((prev) => {
            return {...prev, filter: req};
        });
    };
    return (
        <>
            <Card className='my-4'>
                <Card.Header className='d-flex justify-content-between'>
                    <div>Award Winners</div>
                    <div className='d-flex gap-2'>
                        <Button size='sm' onClick={onCollapsed}>
                            {isCollapsed ? 'Expand' : 'Collapse'}
                        </Button>
                    </div>
                </Card.Header>
                <Card.Body className={isCollapsed ? 'd-none' : ''}>
                    <EntityList
                        itemsLoading={loading}
                        items={items}
                        total={total}
                        config={config}
                        search={onSearch}
                        pagination={onPagination}
                        filter={onFilter}
                    />
                </Card.Body>
            </Card>
        </>
    );
}
export default AwardWinnersList;
