import { GenderTypes, Owner } from 'features/model/owner';
import React, { useEffect, useState } from 'react';
import { Button, Card, Form } from 'react-bootstrap';
import { SubmitHandler, useForm } from 'react-hook-form';
import { VButton } from 'shared/component/button';
import SpinnerForPages from 'shared/component/spinner-for-pages';
import {
    Combobox,
    ComboboxInput,
    ComboboxList,
    ComboboxOption,
    ComboboxPopover,
} from '@reach/combobox';
import '@reach/combobox/styles.css';
import { yupResolver } from '@hookform/resolvers/yup';
import { IconDeviceFloppy, IconEdit, IconTrash } from '@tabler/icons';
import usePlacesAutocomplete, {
    getGeocode,
    getLatLng,
} from 'use-places-autocomplete';

import {
    useActivateOwnerMutation,
    useBlockOwnerMutation,
    useCreateOwnerMutation,
    useDeleteOwnerMutation,
    useUpdateOwnerMutation,
} from "../../owner/api/owner-api-service";
import { schema } from './delivery-form-validation';
import { Delivery } from 'features/model/delivery';
import { useCreateDeliveryMutation } from '../api/delivery-requests-api-service';

export interface DeliveryFormProps {
    viewMode: string;
    title: string;
    selectedItem?: any;
    selectedItemLoading?: boolean;
}
const DeliveryTypes = ['cargo', 'package', 'document', 'relocation']
function DeliveryForm(props: DeliveryFormProps) {

    const [isLoading, setIsLoading] = useState(props?.selectedItemLoading);
    const [isCollapsed, setIsCollapsed] = useState(false);
    const [shouldRedirect, setShouldRedirect] = useState(false);

    const [addNewDelivery, { isLoading: creating }] = useCreateDeliveryMutation();
   
    const [senderLocation, setSenderLocation] = useState({});
    const [receiverLocation, setReceiverLocation] = useState({});
    const {
        control,
        register,
        handleSubmit,
        formState: { errors },
        reset,
        setValue,
    } = useForm<Delivery>({
        resolver: yupResolver(schema),
        mode: 'onBlur',
    });

    const {
        value: senderValue,
        setValue: setSenderValue,
        suggestions: { status: senderStatus, data: senderData },
        clearSuggestions: clearSenderSuggestions,
    } = usePlacesAutocomplete();

    const {
        value: receiverValue,
        setValue: setReceiverValue,
        suggestions: { status: receiverStatus, data: receiverData },
        clearSuggestions: clearReceiverSuggestions,
    } = usePlacesAutocomplete();

    const handleSenderSelect = async (val: string) => {
        setSenderValue(val, false);
        clearSenderSuggestions();
        const results = await getGeocode({ address: val });
        const { lat, lng } = await getLatLng(results[0]);
        setSenderLocation({latitude: lat, longitude: lng});

        // startingPoint({lat, lng});
        // startingAddress(val);
    };
    const handleReceiverSelect = async (val: string) => {
        setReceiverValue(val, false);
        clearReceiverSuggestions();
        const results = await getGeocode({ address: val });
        const { lat, lng } = await getLatLng(results[0]);
        setReceiverLocation({latitude: lat, longitude: lng});
        // startingPoint({lat, lng});
        // startingAddress(val);
    };


  

    const onCreateNewDelivery: SubmitHandler<Delivery> = (newDelivery: Delivery) => {
     
        const deliveryData = {
            ...newDelivery,
            sender_location: {
                ...senderLocation
            },
            reciever_location: {
                ...receiverLocation
            }
        }

       addNewDelivery(deliveryData)
        
    };

    // const onUpdate: SubmitHandler<Delivery> = (updatedOwner: Delivery) => {
    //     // updateOwner(updatedOwner);
    //     setShouldRedirect(true);
    // };

    // const onDelete= () => {
    //   deleteOwner(props.selectedItem.id);
    // }

  

  
    const onCollapsed = () => {
        var isExpand = !isCollapsed;
        setIsCollapsed(isExpand);
    };

    useEffect(() => {

        if (props.selectedItem) {
            reset(props.selectedItem);
        }
        setIsLoading(props?.selectedItemLoading);
    }, [props.selectedItem, reset, props?.selectedItemLoading]);

    return (
        <>

            {isLoading ? (
                <SpinnerForPages />
            ) : (

                <Card>
                    <Card.Header className='d-flex justify-content-between'>
                        <div> {props.title}</div>

                        <div className='d-flex'>
                            <Button size='sm' onClick={onCollapsed}>
                                {isCollapsed ? 'Expand' : 'Collapse'}
                            </Button>
                        </div>
                    </Card.Header>
                    <Card.Body className={isCollapsed ? 'd-none' : ''}>
                        <Form
                            // onSubmit={
                            //     props.viewMode === 'detail'
                            //         ? handleSubmit(onUpdate)
                            //         : handleSubmit(onCreate)
                            // }
                            onSubmit={
                                
                                handleSubmit(onCreateNewDelivery)
                               
                            }
                        >
                            <div className='d-flex mb-4 justify-content-between'>
                                <div className='w-50'>
                                    <h5>Sender And Delivery Information</h5>
                                    <div className='mb-3'>
                                        <Form.Group controlId='senderName'>
                                            <div className='form-group required'>
                                                <Form.Label className='control-label'>
                                                    Sender Name
                                                </Form.Label>
                                            </div>
                                            <Form.Control
                                                isInvalid={
                                                    errors?.sender_name
                                                        ? true
                                                        : false
                                                }
                                                {...register('sender_name')}
                                                type='text'
                                                placeholder='enter name'
                                            />
                                            <Form.Control.Feedback type='invalid'>
                                                {errors?.sender_name?.message}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </div>


                                    <div className='mb-3'>
                                        <Form.Group
                                            className='mb-3'
                                            controlId='formPhoneNumber'
                                        >
                                            <div className='form-group required'>
                                                <Form.Label className='control-label'>
                                                    Sender phone number
                                                </Form.Label>
                                            </div>

                                            <Form.Control
                                                isInvalid={
                                                    errors?.sender_phone_number
                                                        ? true
                                                        : false
                                                }
                                                {...register('sender_phone_number')}
                                                type='text'
                                                placeholder='enter phone number'
                                            />
                                            <Form.Control.Feedback type='invalid'>
                                                {errors?.sender_phone_number?.message}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </div>

                                    <div className='mb-3'>
                                        <Form.Group
                                            className='mb-3'
                                            controlId='formEmail'
                                        >
                                            <Form.Label>Sender Email</Form.Label>
                                            <Form.Control
                                                {...register('sender_email')}
                                                type='text'
                                                placeholder='enter email'
                                            />
                                        </Form.Group>

                                    </div>

                                    <div className='mb-3'>
                                        <Form.Group
                                            className='mb-3'
                                            controlId='formEmail'
                                        >
                                            <Form.Label>Sender Location</Form.Label>
                                            <Combobox
                                                onSelect={handleSenderSelect} className='mb-2'>
                                                <ComboboxInput
                                                    value={senderValue}
                                                    onChange={(e) => setSenderValue(e.target.value)}
                                                    className='form-control'
                                                    placeholder='enter sender location'
                                                />
                                                <ComboboxPopover>
                                                    <ComboboxList>
                                                        {senderStatus === 'OK' &&
                                                            senderData.map(({ place_id, description }) => (
                                                                <ComboboxOption
                                                                    key={place_id}
                                                                    value={description}
                                                                />
                                                            ))}
                                                    </ComboboxList>
                                                </ComboboxPopover>
                                            </Combobox>
                                        </Form.Group>

                                    </div>


                                    <div className='mb-3'>
                                        <Card>
                                            <Card.Header>Sender Address</Card.Header>
                                            <Card.Body>
                                                <Form.Group
                                                    className='mb-2'
                                                    controlId='formCity'
                                                >
                                                    <div className='form-group required'>
                                                        <Form.Label className='control-label'>
                                                            Country
                                                        </Form.Label>
                                                    </div>
                                                    <Form.Control
                                                        isInvalid={
                                                            errors?.sender_address?.country
                                                                ? true
                                                                : false
                                                        }
                                                        {...register(
                                                            'sender_address.country',
                                                        )}
                                                        type='text'
                                                        placeholder='enter country'
                                                    />
                                                    <Form.Control.Feedback type='invalid'>
                                                        {
                                                            errors?.sender_address?.country
                                                                ?.message
                                                        }
                                                    </Form.Control.Feedback>
                                                </Form.Group>

                                                <Form.Group
                                                    className='mb-2'
                                                    controlId='formSubCity'
                                                >
                                                    <div className='form-group required'>
                                                        <Form.Label className='control-label'>
                                                            City
                                                        </Form.Label>
                                                    </div>
                                                    <Form.Control
                                                        isInvalid={
                                                            errors?.sender_address
                                                                ?.city
                                                                ? true
                                                                : false
                                                        }
                                                        {...register(
                                                            'sender_address.city',
                                                        )}
                                                        type='text'
                                                        placeholder='enter city'
                                                    />
                                                    <Form.Control.Feedback type='invalid'>
                                                        {
                                                            errors?.sender_address
                                                                ?.city?.message
                                                        }
                                                    </Form.Control.Feedback>
                                                </Form.Group>

                                                <Form.Group
                                                    className='mb-2'
                                                    controlId='formWoreda'
                                                >
                                                    <div className='form-group'>
                                                        <Form.Label className='control-label'>
                                                            Street Address
                                                        </Form.Label>
                                                    </div>
                                                    <Form.Control
                                                        // isInvalid={
                                                        //     errors?.sender_address?.street_address
                                                        //         ? true
                                                        //         : false
                                                        // }
                                                        {...register(
                                                            'sender_address.street_address',
                                                        )}
                                                        type='text'
                                                        placeholder='enter street address'
                                                    />
                                                    {/* <Form.Control.Feedback type='invalid'>
                                                        {
                                                            errors?.seaddress?.woreda
                                                                ?.message
                                                        }
                                                    </Form.Control.Feedback> */}
                                                </Form.Group>

                                                <Form.Group controlId='formHouseNumber'>
                                                    <div className='form-group'>
                                                        <Form.Label className='control-label'>
                                                            House Number
                                                        </Form.Label>
                                                    </div>
                                                    <Form.Control
                                                        // isInvalid={
                                                        //     errors?.address
                                                        //         ?.house_number
                                                        //         ? true
                                                        //         : false
                                                        // }
                                                        {...register(
                                                            'sender_address.house_number',
                                                        )}
                                                        type='text'
                                                        placeholder='enter house number'
                                                    />
                                                    {/* <Form.Control.Feedback type='invalid'>
                                                        {
                                                            errors?.address
                                                                ?.house_number
                                                                ?.message
                                                        }
                                                    </Form.Control.Feedback> */}
                                                </Form.Group>
                                            </Card.Body>
                                        </Card>

                                        <Card className='mt-3'>
                                            <Card.Header>Delivery Information</Card.Header>
                                            <Card.Body>
                                                <Form.Group
                                                    className='mb-2'
                                                    controlId='formCity'
                                                >
                                                    <div className='form-group required'>
                                                        <Form.Label className='control-label'>
                                                            Delivery Type
                                                        </Form.Label>
                                                    </div>
                                                    <Form.Select className='align-self-flex-start'
                                                        isInvalid={
                                                            errors?.delivery_type ? true : false
                                                        }

                                                        {...register('delivery_type')}
                                                    >

<option value=''>
                                                Select delivery type
                                            </option>

                                                        {DeliveryTypes.map((type) => {
                                                            return (
                                                                <option

                                                                    value={type}
                                                                    label={type}
                                                                ></option>
                                                            );
                                                        })}
                                                    </Form.Select>
                                                    <Form.Control.Feedback type='invalid'>
                                                        {errors?.delivery_type?.message}
                                                    </Form.Control.Feedback>
                                                </Form.Group>

                                                <Form.Group
                                                    className='mb-2'
                                                    controlId='formSubCity'
                                                >
                                                    <div className='form-group required'>
                                                        <Form.Label className='control-label'>
                                                            Delivery Summery
                                                        </Form.Label>
                                                    </div>
                                                    <Form.Control
                                                        isInvalid={
                                                            errors?.delivery_summery
                                                                ? true
                                                                : false
                                                        }
                                                        {...register(
                                                            'delivery_summery',
                                                        )}
                                                        as='textarea'
                                                        placeholder='enter Delivery Summery'
                                                    />
                                                    <Form.Control.Feedback type='invalid'>
                                                        {
                                                            errors?.delivery_summery
                                                                ?.message
                                                        }
                                                    </Form.Control.Feedback>
                                                </Form.Group>


                                            </Card.Body>
                                        </Card>
                                    </div>

                                </div>
                                <div className='w-50 mx-4'>
                                    <h5>Receiver Information</h5>
                                    <div className='mb-3'>
                                        <Form.Group controlId='senderName'>
                                            <div className='form-group required'>
                                                <Form.Label className='control-label'>
                                                    Receiver Name
                                                </Form.Label>
                                            </div>
                                            <Form.Control
                                                isInvalid={
                                                    errors?.reciever_name
                                                        ? true
                                                        : false
                                                }
                                                {...register('reciever_name')}
                                                type='text'
                                                placeholder='enter name'
                                            />
                                            <Form.Control.Feedback type='invalid'>
                                                {errors?.reciever_name?.message}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </div>


                                    <div className='mb-3'>
                                        <Form.Group
                                            className='mb-3'
                                            controlId='formPhoneNumber'
                                        >
                                            <div className='form-group required'>
                                                <Form.Label className='control-label'>
                                                    Receiver phone number
                                                </Form.Label>
                                            </div>

                                            <Form.Control
                                                isInvalid={
                                                    errors?.reciever_phone_number
                                                        ? true
                                                        : false
                                                }
                                                {...register('reciever_phone_number')}
                                                type='text'
                                                placeholder='enter phone number'
                                            />
                                            <Form.Control.Feedback type='invalid'>
                                                {errors?.reciever_phone_number?.message}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </div>

                                    <div className='mb-3'>
                                        <Form.Group
                                            className='mb-3'
                                            controlId='formEmail'
                                        >
                                            <Form.Label>Receiver Email</Form.Label>
                                            <Form.Control
                                                {...register('reciever_email')}
                                                type='text'
                                                placeholder='enter email'
                                            />
                                        </Form.Group>

                                    </div>

                                    <div className='mb-3'>
                                        <Form.Group
                                            className='mb-3'
                                            controlId='formEmail'
                                        >
                                            <Form.Label>Receiver Location</Form.Label>
                                            <Combobox onSelect={handleReceiverSelect} className='mb-2'>
                                                <ComboboxInput
                                                    value={receiverValue}
                                                    onChange={(e) => setReceiverValue(e.target.value)}
                                                    className='form-control'
                                                    placeholder='enter receiver location'
                                                />
                                                <ComboboxPopover>
                                                    <ComboboxList>
                                                        {receiverStatus === 'OK' &&
                                                            receiverData.map(({ place_id, description }) => (
                                                                <ComboboxOption
                                                                    key={place_id}
                                                                    value={description}
                                                                />
                                                            ))}
                                                    </ComboboxList>
                                                </ComboboxPopover>
                                            </Combobox>
                                            {/* <Form.Control.Feedback type='invalid'>
                                                {errors?.receiver_location?.message}
                                            </Form.Control.Feedback> */}
                                        </Form.Group>

                                    </div>



                                    <div className='mb-3'>
                                        <Card>
                                            <Card.Header>Receiver Address</Card.Header>
                                            <Card.Body>
                                                <Form.Group
                                                    className='mb-2'
                                                    controlId='formCity'
                                                >
                                                    <div className='form-group required'>
                                                        <Form.Label className='control-label'>
                                                            Country
                                                        </Form.Label>
                                                    </div>
                                                    <Form.Control
                                                        isInvalid={
                                                            errors?.reciever_address?.country
                                                                ? true
                                                                : false
                                                        }
                                                        {...register(
                                                            'reciever_address.country',
                                                        )}
                                                        type='text'
                                                        placeholder='enter country'
                                                    />
                                                    <Form.Control.Feedback type='invalid'>
                                                        {
                                                            errors?.reciever_address?.country
                                                                ?.message
                                                        }
                                                    </Form.Control.Feedback>
                                                </Form.Group>

                                                <Form.Group
                                                    className='mb-2'
                                                    controlId='formSubCity'
                                                >
                                                    <div className='form-group required'>
                                                        <Form.Label className='control-label'>
                                                            City
                                                        </Form.Label>
                                                    </div>
                                                    <Form.Control
                                                        isInvalid={
                                                            errors?.reciever_address
                                                                ?.city
                                                                ? true
                                                                : false
                                                        }
                                                        {...register(
                                                            'reciever_address.city',
                                                        )}
                                                        type='text'
                                                        placeholder='enter city'
                                                    />
                                                    <Form.Control.Feedback type='invalid'>
                                                        {
                                                            errors?.reciever_address
                                                                ?.city?.message
                                                        }
                                                    </Form.Control.Feedback>
                                                </Form.Group>

                                                <Form.Group
                                                    className='mb-2'
                                                    controlId='formWoreda'
                                                >
                                                    <div className='form-group'>
                                                        <Form.Label className='control-label'>
                                                            Street Address
                                                        </Form.Label>
                                                    </div>
                                                    <Form.Control
                                                        // isInvalid={
                                                        //     errors?.readdress?.woreda
                                                        //         ? true
                                                        //         : false
                                                        // }
                                                        {...register(
                                                            'reciever_address.street_address',
                                                        )}
                                                        type='text'
                                                        placeholder='enter street address'
                                                    />
                                                    {/* <Form.Control.Feedback type='invalid'>
                                                        {
                                                            errors?.receiver_address?.street_address
                                                                ?.message
                                                        }
                                                    </Form.Control.Feedback> */}
                                                </Form.Group>

                                                <Form.Group controlId='formHouseNumber'>
                                                    <div className='form-group'>
                                                        <Form.Label className='control-label'>
                                                            House Number
                                                        </Form.Label>
                                                    </div>
                                                    <Form.Control
                                                        // isInvalid={
                                                        //     errors?.receiver_address
                                                        //         ?.house_number
                                                        //         ? true
                                                        //         : false
                                                        // }
                                                        {...register(
                                                            'reciever_address.house_number',
                                                        )}
                                                        type='text'
                                                        placeholder='enter house number'
                                                    />
                                                    {/* <Form.Control.Feedback type='invalid'>
                                                        {
                                                            errors?.address
                                                                ?.house_number
                                                                ?.message
                                                        }
                                                    </Form.Control.Feedback> */}
                                                </Form.Group>
                                            </Card.Body>
                                        </Card>
                                    </div>

                                    <div className='d-flex mt-5'>
                                        <VButton
                                            
                                            type='submit'
                                            disabled={!(Object.keys(senderLocation).length > 0 && Object.keys(receiverLocation).length > 0)}
                                            variant='primary'
                                        >
                                            {' '}
                                            <IconDeviceFloppy /> Save
                                        </VButton>
                                    </div>
                                </div>
                            </div>
                        </Form>
                    </Card.Body>
                </Card>
            )}
        </>
    );
}

export default DeliveryForm;


