export interface Vehicle {
    id?: string;
    code: string;
    plate_number: string;
    model: string;
    category: string;
    type: string;
    capacity: string;
    color: string;
    is_active?: boolean;
    bolo_due_date?: Date;
    insurance_certificate_due_date?: Date;
    owner_id?: string;
}

export interface careDocument {
    carImage: string;
    carOwnerSlip: string;
}

export const VehicleCategories = ['taxi', 'truc'];
export const VehicleTypes = ['Economy'];
export const VehicleCodes = ['1', '2', '3', '5'];
export const VehicleColors = [
    'Red',
    'Silver',
    'White',
    'Yellow',
    'Black',
    'Gray',
    'Blue',
    'Brown',
    'Green',
    'Orange',
    'Gold',
    'Purple',
];
