import React, {useEffect, useState} from 'react';
import {Button, Card, Form} from 'react-bootstrap';
import EntityList from 'shared/entity/entity-list';
import {EntityConfiguration} from 'shared/entity/models';

import {useLazyGetDriversEaringQuery} from '../api/report-api-services';

interface requestModel {
    driverId: string;
    startDate: string;
    endDate: string;
    format: string;
}
function DriverEaring({...props}) {
   
    const [isCollapsed, setIsCollapsed] = useState(true);
    function formatDate() {
        var d = new Date(),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2) month = '0' + month;
        if (day.length < 2) day = '0' + day;

        return [year, month, day].join('-');
    }
    const [getData, result] = useLazyGetDriversEaringQuery();
    const [items, setItems] = useState(result?.data?.items);
    const [total, setTotal] = useState(result?.data?.total);
    const [loading, setLoading] = useState(true);
    const [startDate, setStartDate] = useState<any>(formatDate);
    const [endDate, setEndDate] = useState<any>(formatDate);
    const [type, setType] = useState<string>('d');

    const [request, setRequest] = useState<requestModel>({
        format: type,
        driverId: props?.selectedProvider.id,
        endDate: endDate,
        startDate: startDate,
    });

    useEffect(() => {
        setRequest({
            format: type,
            driverId: props?.selectedProvider.id,
            endDate: endDate,
            startDate: startDate,
        });
    }, [type, startDate, endDate]);

    useEffect(() => {
        getData(request);
    }, [request, getData]);

    useEffect(() => {
        setItems(result?.data?.items);
        setTotal(result?.data?.total);
        setLoading(result?.isLoading);
    }, [result]);
    let config: EntityConfiguration = {
        rootUrl: '/settings/vehicle-category',
        title: 'Provider Earnings',
        detailUrl: 'detail',
        showNewButton: false,
        hasDetail: false,
        visibleColumn: [
            {key: 'date', name: 'Date', isDate: true},
            {key: 'total', name: 'Income'},
            {key: 'total_trips', name: 'trips'},
        ],
    };

    const onSearch = (req: any) => {};
    const onPagination = (req: any) => {};
    const onFilter = (req: any) => {};
    return (
        <Card className='mt-2'>
            <Card.Header className='d-flex justify-content-between'>
                <div>Provider Earning</div>
                <div className='d-flex gap-4'>
                    <Button
                        size='sm'
                        onClick={() => setIsCollapsed(!isCollapsed)}
                    >
                        {isCollapsed ? 'Expand' : 'Collapse'}
                    </Button>
                </div>
            </Card.Header>
            <Card.Body className={isCollapsed ? 'd-none' : ''}>
                <div className='d-flex justify-content-center items-center'>
                    <div className='mx-4'>
                        <label>Start Date</label>
                        <input
                            onChange={(event) => {
                                setStartDate(event.target.value);
                            }}
                            value={startDate}
                            style={{width: '100%', padding: '0.1rem'}}
                            type='date'
                        />
                    </div>

                    <div className='mx-4'>
                        <label>End Date</label>
                        <input
                            onChange={(event) => {
                                setEndDate(event.target.value);
                            }}
                            value={endDate}
                            style={{width: '100%', padding: '0.1rem'}}
                            type='date'
                            min={startDate}
                        />
                    </div>

                    <div>
                        <label>Type</label>
                        <select
                            required
                            onChange={(event) => {
                                setType(event.target.value);
                                setRequest((prev) => {
                                    return {...prev, type: event.target.value};
                                });
                            }}
                            style={{width: '100%', padding: '0.1rem'}}
                        >
                            <option value='d'>Daily</option>
                            <option value='m'>Monthly</option>
                            <option value='y'>Yearly</option>
                        </select>
                    </div>
                </div>
                <EntityList
                    itemsLoading={loading}
                    items={items || []}
                    total={total || []}
                    config={config}
                    search={onSearch}
                    pagination={onPagination}
                    filter={onFilter}
                />
            </Card.Body>
        </Card>
    );
}
export default DriverEaring;
