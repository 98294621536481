import {Owner} from 'features/model/owner';
import MessagingServices from 'shared/messaging-services';
import {collectionQueryBuilder} from 'shared/model/collection-query-builder';
import {dashboardApiService} from 'store/dashboardApi';

import {render} from '@testing-library/react';

import {ownerEndpoint} from './owner-endpoint';

const UserApiService = dashboardApiService.injectEndpoints({
    endpoints(builder) {
        return {
            listOwner: builder.query({
                query: (query?: any) => ({
                    url: `${ownerEndpoint.list}`,
                    method: 'get',
                    params: collectionQueryBuilder(query),
                }),
            }),
            getOwnerById: builder.query({
                query: (id: string) => ({
                    url: `${ownerEndpoint.detail}/${id}`,
                    method: 'get',
                }),
            }),
            createOwner: builder.mutation({
                query: (newData: any) => ({
                    url: `${ownerEndpoint.create}`,
                    method: 'post',
                    data: newData,
                }),
                async onQueryStarted(id, {dispatch, queryFulfilled}) {
                    try {
                        const {data} = await queryFulfilled;
                        render(
                            <MessagingServices
                                message='Owner Created Successfully'
                                show={true}
                                type={'success'}
                            />,
                        );
                    } catch (error) {
                        render(
                            <MessagingServices
                                message={error.error.data.message}
                                show={true}
                                type={'error'}
                            />,
                        );
                    }
                },
            }),
            updateOwner: builder.mutation({
                query: (updatedData: Owner) => ({
                    url: `${ownerEndpoint.update}?owner_id=${updatedData.id}`,
                    method: 'post',
                    data: updatedData,
                }),
                async onQueryStarted(id, {dispatch, queryFulfilled}) {
                    try {
                        const {data} = await queryFulfilled;

                        render(
                            <MessagingServices
                                message='Owner updated successfully'
                                show={true}
                                type={'success'}
                            />,
                        );
                    } catch (error) {
                        render(
                            <MessagingServices
                                message={error.error.data.message}
                                show={true}
                                type={'error'}
                            />,
                        );
                    }
                },
            }),
            deleteOwner: builder.mutation({
                query: (id: string) => ({
                    url: `${ownerEndpoint.delete}/${id}`,
                    method: 'delete',
                }),
            }),
            activateOwner: builder.mutation({
                query: (id: string) => ({
                    url: `${ownerEndpoint.activate}`,
                    method: 'post',
                    data: {id: id},
                }),
                async onQueryStarted(id, {dispatch, queryFulfilled}) {
                    try {
                        const {data} = await queryFulfilled;

                        render(
                            <MessagingServices
                                message='Owner activated successfully'
                                show={true}
                                type={'success'}
                            />,
                        );
                    } catch (error) {
                        render(
                            <MessagingServices
                                message='Something went wrong owner not activated'
                                show={true}
                                type={'error'}
                            />,
                        );
                    }
                },
            }),
            blockOwner: builder.mutation({
                query: (id: string) => ({
                    url: `${ownerEndpoint.block}`,
                    method: 'post',
                    data: {
                        id: id,
                    },
                }),
                async onQueryStarted(id, {dispatch, queryFulfilled}) {
                    try {
                        const {data} = await queryFulfilled;

                        render(
                            <MessagingServices
                                message='Owner deactivated successfully'
                                show={true}
                                type={'success'}
                            />,
                        );
                    } catch (error) {
                        render(
                            <MessagingServices
                                message='Something went wrong owner not blocked'
                                show={true}
                                type={'error'}
                            />,
                        );
                    }
                },
            }),
        };
    },
});

export const {
    useLazyListOwnerQuery,
    useGetOwnerByIdQuery,
    useCreateOwnerMutation,
    useUpdateOwnerMutation,
    useDeleteOwnerMutation,
    useActivateOwnerMutation,
    useBlockOwnerMutation,
} = UserApiService;
