import React, {useEffect, useState} from 'react';
import {userService} from 'services/auth/user-services';
import EntityList from 'shared/entity/entity-list';
import {EntityConfiguration} from 'shared/entity/models';
import {CollectionQuery} from 'shared/model/collection.model';
import {useLazyListAwardQuery} from '../api/award-api-service';
function AwardList() {
    const [request, setRequest] = useState<CollectionQuery>({
        skip: 0,
        top: 10,
        includes: ['created_by'],
        orderBy: [
            {
                field: 'createdAt',
                direction: 'desc',
            },
        ],
    });
    const [getData, result] = useLazyListAwardQuery();
    const [items, setItems] = useState(result?.data?.items);
    const [total, setTotal] = useState(result?.data?.total);
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        getData(request, false);
    }, [request, getData]);
    useEffect(() => {
        setItems(result?.data?.items);
        setTotal(result?.data?.total);
        setLoading(result?.isLoading);
    }, [result]);
    let config: EntityConfiguration = {
        rootUrl: '/award',
        title: 'awards List',
        detailUrl: '/award/detail',
        visibleColumn: [
            {key: 'title', name: 'title'},
            {key: 'is_active', name: 'Is Active', isBoolean: true},
            {
                key: ['created_by', 'first_name'],
                name: 'Created By',
                postFix: {key: ['created_by', 'last_name']},
            },
            {
                key: 'created_at',
                name: 'Created At',
                isDate: true,
            },
            {
                key: 'start_date',
                name: 'Start Date',
                isDate: true,
            },
            {
                key: 'end_date',
                name: 'End Date',
                isDate: true,
            },
        ],
        filter: [
            [
                {
                    field: 'is_active',
                    value: 'true',
                    operator: '=',
                    fieldName: 'Status',
                    name: 'Active',
                },
                {
                    field: 'is_active',
                    value: 'false',
                    operator: '=',
                    name: 'Inactive',
                    fieldName: 'Status',
                },
            ],
        ],
    };
    const onSearch = (req: any) => {
        setRequest((prev) => {
            return {...prev, search: req};
        });
    };
    const onPagination = (req: any) => {
        setRequest((prev) => {
            return {...prev, skip: req.skip, top: req.top};
        });
    };
    const onFilter = (req: any) => {
        setRequest((prev) => {
            return {...prev, filter: req};
        });
    };
    return (
        <EntityList
            itemsLoading={loading}
            items={items}
            total={total}
            config={config}
            search={onSearch}
            pagination={onPagination}
            filter={onFilter}
        />
    );
}
export default AwardList;
