import {Award} from 'features/model/award';
import MessagingServices from 'shared/messaging-services';
import {collectionQueryBuilder} from 'shared/model/collection-query-builder';
import {dashboardApiService} from 'store/dashboardApi';
import {render} from '@testing-library/react';
import {awardEndpoint} from './award-endpoint';
const AwardApiService = dashboardApiService.injectEndpoints({
    endpoints(builder) {
        return {
            listAward: builder.query({
                query: (query?: any) => ({
                    url: `${awardEndpoint.list}`,
                    method: 'get',
                    params: collectionQueryBuilder(query),
                }),
            }),
            getAwardById: builder.query({
                query: (id: string) => ({
                    url: `${awardEndpoint.detail}/${id}`,
                    method: 'get',
                }),
            }),
            createAward: builder.mutation({
                query: (newData: any) => ({
                    url: `${awardEndpoint.create}`,
                    method: 'post',
                    data: newData,
                }),
                async onQueryStarted(id, {dispatch, queryFulfilled}) {
                    try {
                        const {data} = await queryFulfilled;
                        render(
                            <MessagingServices
                                message='Award Created Successfully'
                                show={true}
                                type={'success'}
                            />,
                        );
                    } catch (error) {
                        render(
                            <MessagingServices
                                message='Something went wrong Award not created'
                                show={true}
                                type={'error'}
                            />,
                        );
                    }
                },
            }),
            updateAward: builder.mutation({
                query: (updatedData: Award) => ({
                    url: `${awardEndpoint.update}/${updatedData.id}`,
                    method: 'post',
                    data: updatedData,
                }),
                async onQueryStarted(id, {dispatch, queryFulfilled}) {
                    try {
                        const {data} = await queryFulfilled;
                        render(
                            <MessagingServices
                                message='Award updated successfully'
                                show={true}
                                type={'success'}
                            />,
                        );
                    } catch (error) {
                        render(
                            <MessagingServices
                                message='Something went wrong Award not updated'
                                show={true}
                                type={'error'}
                            />,
                        );
                    }
                },
            }),
            deleteAward: builder.mutation({
                query: (id: string) => ({
                    url: `${awardEndpoint.delete}/${id}`,
                    method: 'delete',
                }),
            }),
            addAwardItem: builder.mutation<any, any>({
                query: (item: any) => ({
                    url: `${awardEndpoint.addItem}`,
                    method: 'post',
                    data: item,
                }),
                async onQueryStarted(
                    item,
                    {dispatch, queryFulfilled, getState},
                ) {
                    try {
                        const {data} = await queryFulfilled;
                        
                        render(
                            <MessagingServices
                                message='Award Item Add Successfully'
                                show={true}
                                type={'success'}
                            />,
                        );
                    } catch (e) {
                        if (e.error) {
                            render(
                                <MessagingServices
                                    message='Something went wrong award item not added'
                                    show={true}
                                    type={'error'}
                                />,
                            );
                        }
                    }
                },
                invalidatesTags: ['AwardItems'],
            }),
            listAwardItems: builder.query<any, any>({
                query: (query?: any) => ({
                    url: `${awardEndpoint.getItem}`,
                    method: 'get',
                    params: collectionQueryBuilder(query),
                }),
                providesTags: ['AwardItems'],
            }),
            removeItem: builder.mutation({
                query: (id: string) => ({
                    url: `${awardEndpoint.removeItem}/${id}`,
                    method: 'delete',
                }),
                async onQueryStarted(id, {dispatch, queryFulfilled}) {
                    try {
                        const {data} = await queryFulfilled;
                        render(
                            <MessagingServices
                                message='Award Item Deleted successfully'
                                show={true}
                                type={'success'}
                            />,
                        );
                    } catch (error) {
                        render(
                            <MessagingServices
                                message='Something went wrong award item not deleted'
                                show={true}
                                type={'error'}
                            />,
                        );
                    }
                },
                invalidatesTags: ['AwardItems'],
            }),
            generateWinners: builder.query({
                query: (id: string) => ({
                    url: `${awardEndpoint.generateWinners}/${id}`,
                    method: 'get',
                }),
                async onQueryStarted(id, {dispatch, queryFulfilled}) {
                    try {
                        const {data} = await queryFulfilled;
                        render(
                            <MessagingServices
                                message='Winner generated successfully'
                                show={true}
                                type={'success'}
                            />,
                        );
                    } catch (error) {
                        render(
                            <MessagingServices
                                message='Something went wrong winner generated successfully'
                                show={true}
                                type={'error'}
                            />,
                        );
                    }
                },
            }),
            getAwardWinners: builder.query({
                query: ({id, params}) => ({
                    url: `${awardEndpoint.getAwardWinners}/${id}`,
                    method: 'get',
                    params: collectionQueryBuilder(params),
                }),
            }),
        };
    },
});
export const {
    useLazyListAwardQuery,
    useGetAwardByIdQuery,
    useCreateAwardMutation,
    useUpdateAwardMutation,
    useDeleteAwardMutation,
    useAddAwardItemMutation,
    useLazyListAwardItemsQuery,
    useRemoveItemMutation,
    useLazyGenerateWinnersQuery,
    useLazyGetAwardWinnersQuery,
} = AwardApiService;
