import {Passenger} from 'features/model/passenger';
import {Provider} from 'features/model/provider';
import React, {useEffect, useState} from 'react';
import {Button, Card} from 'react-bootstrap';
import {VButton} from 'shared/component/button';
import SpinnerForPages from 'shared/component/spinner-for-pages';

import {IconTrash} from '@tabler/icons';

import {
    useActivatePassengerMutation,
    useBlockPassengerMutation,
} from '../api/passanger-api-service';
import PassengerTripHistory from './passenger-trip-history';
import styles from './style.module.css';

interface PassangerDetailProps {
    selectedPassanger: any;
    selectedPassangerLoading: boolean;
}

function PassangerDetail(props: PassangerDetailProps) {
    const [selectedPassanger, setSelectedPassenger] = useState<Passenger>(
        props.selectedPassanger,
    );
    const [skip, setSkip] = useState(true);
    const [enabled, setEnabled] = useState(() => selectedPassanger?.enabled);
    const [isCollapsed, setIsCollapsed] = useState(false);

    const [
        activatePassanger,
        {data: activeData, isLoading: activating, isSuccess: successActivated},
    ] = useActivatePassengerMutation();
    const [
        blockPassanger,
        {data: blockData, isLoading: blocking, isSuccess: successBlocked},
    ] = useBlockPassengerMutation();

    useEffect(() => {}, [selectedPassanger?.enabled]);

    useEffect(() => {
        setSelectedPassenger(props.selectedPassanger);
        if (props.selectedPassanger) {
            setSkip(false);
        }
    }, [props.selectedPassanger]);

    const onCollapsed = () => {
        var isExpand = !isCollapsed;
        setIsCollapsed(isExpand);
    };

    const onActivate = () => {
        activatePassanger(selectedPassanger?.id);
        setEnabled(selectedPassanger?.enabled);
    };
    const onBlock = () => {
        blockPassanger(selectedPassanger?.id);
        setEnabled(selectedPassanger?.enabled);
    };
    useEffect(() => {
        if (activeData && successActivated) setSelectedPassenger(activeData);
    }, [activeData, successActivated]);

    useEffect(() => {
        if (blockData && successBlocked) setSelectedPassenger(blockData);
    }, [blockData, successBlocked]);
    return (
        <div className='d-flex flex-column' style={{gap: '20px'}}>
            {props.selectedPassangerLoading ? (
                <SpinnerForPages />
            ) : (
                <>
                    <Card>
                        <Card.Header className='d-flex justify-content-between'>
                            <div> Passenger Detail</div>
                            <div className='d-flex'>
                                {''}
                                <Button size='sm' onClick={onCollapsed}>
                                    {isCollapsed ? 'Expand' : 'Collapse'}
                                </Button>
                            </div>
                        </Card.Header>
                        <Card.Body className={isCollapsed ? 'd-none' : ''}>
                            <div className='border mt-2 mx-2 pt-2'>
                                {/* Name */}
                                <div className='p-2 pt-0 pb-0 d-flex'>
                                    <div
                                        className={`p-2 w-25 ${styles.listKey} `}
                                    >
                                        Name
                                    </div>
                                    <div className={`p-2 w-75 ${styles.list} `}>
                                        {selectedPassanger?.name}
                                    </div>
                                </div>

                                {/* Gender */}
                                <div className='p-2 pt-0 pb-0 d-flex'>
                                    <div
                                        className={`p-2 w-25 ${styles.listKey} `}
                                    >
                                        Gender
                                    </div>
                                    <div className={`p-2 w-75 ${styles.list} `}>
                                        {selectedPassanger?.gender}
                                    </div>
                                </div>
                                {/* Email */}
                                <div className='p-2 pt-0 pb-0 d-flex'>
                                    <div
                                        className={`p-2 w-25 ${styles.listKey} `}
                                    >
                                        Email
                                    </div>
                                    <div className={`p-2 w-75 ${styles.list} `}>
                                        {selectedPassanger?.email}
                                    </div>
                                </div>
                                {/* Phone */}
                                <div className='p-2 pt-0 pb-0 d-flex'>
                                    <div
                                        className={`p-2 w-25 ${styles.listKey} `}
                                    >
                                        Phone Number
                                    </div>
                                    <div className={`p-2 w-75 ${styles.list} `}>
                                        {selectedPassanger?.phone_number}
                                    </div>
                                </div>
                                {/* Emergency */}
                                {selectedPassanger?.emergency_contact && (
                                    <div className='p-2 pt-0 pb-0 d-flex'>
                                        <div
                                            className={`p-2 w-25 ${styles.listKey} `}
                                        >
                                            {' '}
                                            Emergency Contact
                                        </div>
                                        <div
                                            className={`p-2 w-75 ${styles.list} `}
                                        >
                                            {
                                                selectedPassanger?.emergency_contact
                                            }
                                        </div>
                                    </div>
                                )}

                                {/* status */}
                                <div className='p-2 pt-0 pb-0 d-flex'>
                                    <div
                                        className={`p-2 w-25 ${styles.listKey} `}
                                    >
                                        Status{' '}
                                    </div>
                                    <div className={`p-2 w-75 ${styles.list} `}>
                                        {selectedPassanger?.enabled
                                            ? 'Active'
                                            : 'Inactive'}
                                    </div>
                                </div>

                                {/* <div className="p-2 pt-0 pb-0 d-flex">
                <div className={`p-2 w-25 ${styles.listKey} `}>City </div>
                <div className={`p-2 w-75 ${styles.list} `}>
                  {selectedPassanger?.address?.city }
                </div>
              </div>
              <div className="p-2 pt-0 pb-0 d-flex">
                <div className={`p-2 w-25 ${styles.listKey} `}>SubCity </div>
                <div className={`p-2 w-75 ${styles.list} `}>
                  {selectedPassanger?.address?.sub_city }
                </div>
              </div>
              <div className="p-2 pt-0 pb-0 d-flex">
                <div className={`p-2 w-25 ${styles.listKey} `}>Woreda </div>
                <div className={`p-2 w-75 ${styles.list} `}>
                  {selectedPassanger?.address?.woreda }
                </div>
              </div>
              <div className="p-2 pt-0 pb-0 d-flex">
                <div className={`p-2 w-25 ${styles.listKey} `}>House Number </div>
                <div className={`p-2 w-75 ${styles.list} `}>
                  {selectedPassanger?.address?.house_number }
                </div>
              </div> */}
                            </div>

                            <div className='p-2 pt-0 mt-3 pb-0 d-flex'>
                                {selectedPassanger?.enabled === true ? (
                                    <VButton
                                        onClick={onBlock}
                                        disabled={false}
                                        variant='danger'
                                        isLoading={blocking}
                                        className='mr-2'
                                    >
                                        {' '}
                                        <IconTrash />
                                        Block
                                    </VButton>
                                ) : (
                                    <VButton
                                        onClick={onActivate}
                                        disabled={false}
                                        variant='success'
                                        isLoading={activating}
                                        className='mr-2'
                                    >
                                        <IconTrash />
                                        Activate
                                    </VButton>
                                )}
                            </div>
                        </Card.Body>
                    </Card>

                    <PassengerTripHistory />
                </>
            )}
        </div>
    );
}

export default PassangerDetail;
