export const dashboardEndpoint = {
    getTotalNewPassengers: `passengers/count-passengers`,
    getNewIncome: `owners/get-owners`,
    getTotalPassengers: `passengers/count-passengers`,
    getTotalDriver: `drivers/group-drivers`,
    getTotalIncome: `ride-requests/get-total-company-earning`,
    getIncomeByVehicleType: `reports/get-company-earning-by-year`,
    getTotalTransaction: `reports/get-total-transaction`,
    total_drivers_credit: `credits/get-total-credit`,
};
