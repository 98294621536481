import '@reach/combobox/styles.css';

import React, {useEffect} from 'react';
import usePlacesAutocomplete, {
    getGeocode,
    getLatLng,
} from 'use-places-autocomplete';

import {
    Combobox,
    ComboboxInput,
    ComboboxList,
    ComboboxOption,
    ComboboxPopover,
} from '@reach/combobox';

type PlacesProps = {
    startingPoint: (position: any) => void;
    startingAddress: (pickup_address: any) => void;
    isReset: boolean;
};

function StartingPoint({startingPoint, startingAddress, isReset}: PlacesProps) {
    const {
        ready,
        value,
        setValue,
        suggestions: {status, data},
        clearSuggestions,
    } = usePlacesAutocomplete();

    const handleSelect = async (val: string) => {
        setValue(val, false);
        clearSuggestions();
        const results = await getGeocode({address: val});
        const {lat, lng} = await getLatLng(results[0]);
        startingPoint({lat, lng});
        startingAddress(val);
    };

    useEffect(() => {
        if (isReset) {
            setValue('');
            startingAddress(undefined);
            startingPoint({lat: undefined, lng: undefined});
        }
    }, [isReset]);

    return (
        <Combobox onSelect={handleSelect} className='mb-2'>
            <ComboboxInput
                value={value}
                onChange={(e) => setValue(e.target.value)}
                disabled={!ready}
                className='combobox-input input-sm'
                placeholder='Select pickup address'
            />
            <ComboboxPopover>
                <ComboboxList>
                    {status === 'OK' &&
                        data.map(({place_id, description}) => (
                            <ComboboxOption
                                key={place_id}
                                value={description}
                            />
                        ))}
                </ComboboxList>
            </ComboboxPopover>
        </Combobox>
    );
}

export default StartingPoint;
