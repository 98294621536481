import { useEffect, useState } from 'react';
import { Area, AreaChart, ResponsiveContainer, CartesianGrid, Tooltip, XAxis, YAxis } from 'recharts';

interface AreaChartsProps {
    items: any[];
}
export default function AreaCharts(props: AreaChartsProps) {

    return (
        <ResponsiveContainer width={"100%"}>
            <AreaChart
                height={320}
                data={props.items}
                margin={{
                    top: 10,
                    right: 30,
                    left: 0,
                    bottom: 0,
                }}
            >
                <CartesianGrid strokeDasharray='3 3' />
                <XAxis dataKey='month' />
                <YAxis />
                <Tooltip />

                <Area
                    type='monotone'
                    dataKey='taxi'
                    stackId='1'
                    stroke='#d36b10'
                    fill='#d36b10'
                />
                {/* <Area
                type='monotone'
                dataKey='truck'
                stackId='1'
                stroke='red'
                fill='red'
            /> */}
                <Area
                    type='monotone'
                    dataKey='total'
                    stackId='1'
                    stroke='green'
                    fill='green'
                />
            </AreaChart>
        </ResponsiveContainer>

    );
}
