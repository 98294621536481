import { IconBellRinging, IconLock, IconLogout, IconUser } from '@tabler/icons'
import React, { useEffect, useState } from 'react'
import { Image } from 'react-bootstrap'
import './header.scss'
import user from 'assets/img/user.jpg'
import { Link, NavLink } from 'react-router-dom'
import { userService } from 'services/auth/user-services'
import { history } from '../../services/auth/history'
import { useCountUnReportReadQuery } from 'features/emergency-report/api/emergency-report-api-service'
import { CollectionQuery } from 'shared/model/collection.model'
import useSocketIoHook, {
  socket,
} from '../../features/provider_tracking/api/socket-io-hook'
function Header() {
  const request: CollectionQuery = {
    filter: [
      [
        {
          field: 'is_viewed',
          value: 'false',
          operator: '=',
        },
      ],
    ],
  }
  const [reports, setReports] = useState<number>(0)
  const [firstData, setFirstData] = useState<string>()
  const { data } = useCountUnReportReadQuery(request)
  const userRole: string[] = userService.getCurrentUserRoles
  const currentRole: string = userService.currentRole
  const filteredRole =
    userRole?.length > 0 ? userRole.filter((rol) => rol !== currentRole) : []
  const [currentUser, setCurrentUser] = useState(userService.currentUser)
  const changeRole = (role) => {
    localStorage.setItem('currentRole', JSON.stringify(role))
    history.push('/dashboard')
    window.location.reload()
  }
  const goBack = () => {
    history.back()
  }
  const { emergencyReport } = useSocketIoHook()
  useEffect(() => {
    setReports(data?.count)
  }, [data])
  useEffect(() => {
    if (emergencyReport) setReports((prev) => prev + 1)
  }, [emergencyReport])
  useEffect(() => {}, [socket])
  return (
    <div className="d-flex">
      <div className="align-self-start" onClick={goBack}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="icon icon-tabler icon-tabler-chevrons-left"
          width="44"
          height="44"
          viewBox="0 0 24 24"
          strokeWidth="1.5"
          stroke="#ffffff"
          fill="none"
          // stroke-linecap="round"
          // stroke-linejoin="round"
        >
          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
          <polyline points="11 7 6 12 11 17" />
          <polyline points="17 7 12 12 17 17" />
        </svg>
      </div>
      <div className="align-self-center ml-2 position-relative">
        <Link to="/emergency-report">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="icon icon-tabler icon-tabler-bell  text-dark bg-opacity-25"
            width="35"
            height="35"
            viewBox="0 0 24 24"
            strokeWidth="2"
            stroke="currentColor"
            fill="white"
          >
            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
            <path d="M10 5a2 2 0 0 1 4 0a7 7 0 0 1 4 6v3a4 4 0 0 0 2 3h-16a4 4 0 0 0 2 -3v-3a7 7 0 0 1 4 -6"></path>
            <path d="M9 17v1a3 3 0 0 0 6 0v-1"></path>
          </svg>
          <span
            style={{ height: 25, width: 25 }}
            className="mt-2 rounded-circle badge badge-pill bg-danger position-absolute  top-0 start-100 translate-middle  border-0"
          >
            {reports}
          </span>
        </Link>
      </div>
      <div className="dropdown mx-4">
        <a
          className="btn border-0"
          type="button"
          id="dropdownMenuLink"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          <div className="d-flex">
            <div>
              <Image
                style={{ width: 35, height: 35 }}
                roundedCircle
                src={user}
              />
            </div>
            {/* <div className='mx-1'>
                            <div style={{ fontSize: 14, color: 'white' }}> Shanbel Kassa</div>
                            <div style={{ fontSize: 12, color: 'white' }}>Admin</div>
                        </div> */}
          </div>
        </a>
        <ul className="dropdown-menu" aria-labelledby="dropdownMenuLink">
          <li className="dropdown-text">
            <span className="p-2">
              {currentUser?.first_name} {currentUser?.last_name}
            </span>
          </li>
          <li>
            <hr className="dropdown-divider" />
          </li>
          <li>
            <a className="dropdown-item" href="/profile">
              {' '}
              Profile
            </a>
          </li>
          {/* <li>
            <a className="dropdown-item" href="#">
              {' '}
              Change Password
            </a>
          </li> */}
          <li>
            <NavLink
              onClick={() => localStorage.clear()}
              className="dropdown-item"
              to="/login"
            >
              Logout
            </NavLink>
          </li>
          {filteredRole.map((element) => {
            return (
              <li key={element}>
                <Link
                  onClick={() => changeRole(element)}
                  className="dropdown-item"
                  to=""
                >
                  {element}
                </Link>
              </li>
            )
          })}
        </ul>
      </div>
      {/* <div>
                <Image style={{ width: 40, height: 40 }} roundedCircle src='https://thumbs.dreamstime.com/b/lonely-elephant-against-sunset-beautiful-sun-clouds-savannah-serengeti-national-park-africa-tanzania-artistic-imag-image-106950644.jpg'></Image>
                <DropdownButton variant='white' id="dropdown-item-button" title="Dropdown button">
                    <Dropdown.Item as="button">Action</Dropdown.Item>
                    <Dropdown.Item as="button">Another action</Dropdown.Item>
                    <Dropdown.Item as="button">Something else</Dropdown.Item>
                </DropdownButton>
            </div > */}
    </div>
  )
}
export default Header
