export const applicationSetting: ApplicationSetting = {
    name: 'EDL',
    role: [
        {
            name: 'Admin',
            menu: [
                {name: 'Dashboard', url: '/dashboard'},
                {name: 'Registration', url: '/about'},
                {name: 'Vehicle', url: '/public-vehicle'},
                {name: 'Provider', url: '/public-driver'},
                {name: 'Customer', url: '/Passanger'},
                {name: 'Requests', url: '/requests'},
                {name: 'Push Notification', url: '/notification'},
                {name: 'Feedback', url: '/feedback'},
                {name: 'Reports', url: '/report'},
                {name: 'Award', url: '/award'},
                {name: "Delivery Requests", url: '/delivery-requests'},
                // {name: "Online Providers", url: '/online-providers'},
                {name: 'Emergency Report', url: '/emergency-report'},
                {name: 'Settings', url: '/settings'},
                {name: 'Users', url: '/users'}
            ],
        },
        {
            name: 'Operator',
            menu: [
                {name: 'Dashboard', url: '/dashboard'},
                {name: 'Vehicle', url: '/public-vehicle'},
                {name: 'Provider', url: '/public-driver'},
                {name: 'Customer', url: '/Passanger'},
                {name: 'Requests', url: '/requests'},
                {name: 'Provider Tracking', url: '/driver_tracking'},
                {name: 'Feedback', url: '/feedback'},
                {name: 'Award', url: '/award'},
                {name: 'Emergency Report', url: '/emergency-report'},
                {name: "Delivery Requests", url: '/delivery-requests'},
                // {name: "Online Providers", url: '/online-providers'},
            ],
        },
        {
            name: 'BankTeller',
            menu: [
                {name: 'Dashboard', url: '/dashboard'},
                {name: 'Bank Credit', url: '/bank-credit'},
            ],
        },
        {
            name: 'SuperAdmin',
            menu: [
                {name: 'Dashboard', url: '/dashboard'},
                {name: 'Registration', url: '/about'},
                {name: 'Vehicle', url: '/public-vehicle'},
                {name: 'Provider', url: '/public-driver'},
                {name: 'Customer', url: '/Passanger'},
                {name: 'Requests', url: '/requests'},
                {name: 'Provider Tracking', url: '/driver_tracking'},
                {name: 'Push Notification', url: '/notification'},
                {name: 'Feedback', url: '/feedback'},
                // {name: 'Bank Credit', url: '/bank-credit'},
                {name: 'Reports', url: '/report'},
                {name: 'Award', url: '/award'},
                {name: "Delivery Requests", url: '/delivery-requests'},
                // {name: "Online Providers", url: '/online-providers'},
                {name: 'Emergency Report', url: '/emergency-report'},
                {name: 'Settings', url: '/settings'},
                {name: 'Users', url: '/users'},
            ],
        },
        {
            name: 'FinanceOfficer',
            menu: [
                {name: 'Dashboard', url: '/dashboard'},
                {name: 'Provider', url: '/public-driver'},
                {name: 'Reports', url: '/report'},
                {name: 'Award', url: '/award'},
            ],
        },
    ],
};

export interface Menu {
    name: string;
    url: string;
}

export interface Role {
    name: string;
    menu: Menu[];
}
export interface ApplicationSetting {
    name: string;
    role: Role[];
}
