import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {Button, Card} from 'react-bootstrap';
import SpinnerForPages from 'shared/component/spinner-for-pages';

import MapContainer from '../components/google_map';
import styles from './style.module.css';

interface PublicVehicleDetailProps {
    selectedRequest: any;
    selectedRequestLoading: boolean;
    latitude: any;
    longitude: any;
}
function EmergencyReportDetail(props /*: PublicVehicleDetailProps */) {
    const [selectedRequest, setSelectedVehicle] = useState(
        props.selectedRequest,
    );

    const [showMap, setShowMap] = useState(false);
    const [skip, setSkip] = useState(true);
    const [isCollapsed, setIsCollapsed] = useState(false);
    const dateFormater = (date) => {
        if (date) {
            const data = new Date(date);
            // Results below assume UTC timezone - your results may vary
            const result = new Intl.DateTimeFormat('en-GB', {
                year: 'numeric',
                month: 'short',
                day: '2-digit',
                hour: '2-digit',
                minute: '2-digit',
                hour12: false,
                timeZoneName: 'short',
            }).format(data);
      
            return result;
        }
    };

    useEffect(() => {
        setSelectedVehicle(props.selectedRequest);
        if (props.selectedRequest) {
            setSkip(false);
        }
    }, [props.selectedRequest]);

    const onCollapsed = () => {
        var isExpand = !isCollapsed;
        setIsCollapsed(isExpand);
    };

    const onShowMap = () => {
        setShowMap(true);
    };

    return (
        <>
            {props.selectedRequestLoading ? (
                <SpinnerForPages />
            ) : (
                <Card className=' h-100'>
                    <Card.Header className='d-flex justify-content-between'>
                        <div> Emergency Report Detail</div>
                        <div className='d-flex gap-2'>
                            <Button size='sm' onClick={onCollapsed}>
                                {isCollapsed ? 'Expand' : 'Collapse'}
                            </Button>
                        </div>
                    </Card.Header>
                    <Card.Body className={isCollapsed ? 'd-none' : ''}>
                        <div className='d-flex '>
                            <div
                                style={{width: '65%'}}
                                className='border mt-2 mx-2 pt-2'
                            >
                                {/* model */}

                                <div className='p-2 pt-0 pb-0 d-flex'>
                                    <div
                                        className={`p-2 w-25 ${styles.listKey} `}
                                    >
                                        Current Location
                                    </div>
                                    <div className={`p-2 w-75 ${styles.list} `}>
                                        <Button size='sm' onClick={onShowMap}>
                                            View on map
                                        </Button>
                                    </div>
                                </div>
                                <div className='p-2 pt-0 pb-0 d-flex'>
                                    <div
                                        className={`p-2 w-25 ${styles.listKey} `}
                                    >
                                        Drop Off Address
                                    </div>
                                    <div className={`p-2 w-75 ${styles.list} `}>
                                        {
                                            selectedRequest?.trip
                                                ?.drop_off_address
                                        }
                                    </div>
                                </div>

                                {/* Code */}
                                <div className='p-2 pt-0 pb-0 d-flex'>
                                    <div
                                        className={`p-2 w-25 ${styles.listKey} `}
                                    >
                                        Pickup Address
                                    </div>
                                    <div className={`p-2 w-75 ${styles.list} `}>
                                        {selectedRequest?.trip?.pickup_address}
                                    </div>
                                </div>

                                <div className='p-2 pt-0 pb-0 d-flex'>
                                    <div
                                        className={`p-2 w-25 ${styles.listKey} `}
                                    >
                                        Vehicle Type
                                    </div>
                                    <div className={`p-2 w-75 ${styles.list} `}>
                                        {selectedRequest?.trip?.vehicle_type}
                                    </div>
                                </div>

                                {/* Code */}
                                <div className='p-2 pt-0 pb-0 d-flex'>
                                    <div
                                        className={`p-2 w-25 ${styles.listKey} `}
                                    >
                                        Vehicle Category
                                    </div>
                                    <div className={`p-2 w-75 ${styles.list} `}>
                                        {
                                            selectedRequest?.trip
                                                ?.vehicle_category
                                        }
                                    </div>
                                </div>

                                {/* category */}
                                <div className='p-2 pt-0 pb-0 d-flex'>
                                    <div
                                        className={`p-2 w-25 ${styles.listKey} `}
                                    >
                                        {' '}
                                        Status
                                    </div>
                                    <div className={`p-2 w-75 ${styles.list} `}>
                                        {selectedRequest?.trip?.status}
                                    </div>
                                </div>

                                <div className='p-2 pt-0 pb-0 d-flex'>
                                    <div
                                        className={`p-2 w-25 ${styles.listKey} `}
                                    >
                                        Starting Time
                                    </div>
                                    <div className={`p-2 w-75 ${styles.list} `}>
                                        {dateFormater(
                                            selectedRequest?.trip
                                                ?.starting_time,
                                        )}
                                    </div>
                                </div>

                                <div className='p-2 pt-0 pb-0 d-flex'>
                                    <div
                                        className={`p-2 w-25 ${styles.listKey} `}
                                    >
                                        Completed Time
                                    </div>
                                    <div className={`p-2 w-75 ${styles.list} `}>
                                        {dateFormater(
                                            selectedRequest?.trip
                                                ?.completed_time,
                                        )}
                                    </div>
                                </div>
                            </div>

                            <div style={{width: '35%'}}>
                                <div className='border mt-2 mx-2 pt-2'>
                                    <h5 className='mx-2'>Provider</h5>
                                    {/* Name */}
                                    <div className='p-2 pt-0 pb-0 d-flex'>
                                        <div
                                            className={`p-2 w-100 ${styles.listKey} `}
                                        >
                                            Name:{' '}
                                            {
                                                selectedRequest?.trip?.driver
                                                    ?.first_name
                                            }{' '}
                                            {
                                                selectedRequest?.trip?.driver
                                                    ?.last_name
                                            }
                                        </div>
                                    </div>
                                    {/* Phone */}
                                    <div className='p-2 pt-0 pb-0 d-flex'>
                                        <div
                                            className={`p-2 w-100 ${styles.listKey} `}
                                        >
                                            Phone:{' '}
                                            {
                                                selectedRequest?.trip?.driver
                                                    ?.phone_number
                                            }
                                        </div>
                                    </div>

                                    {/* Gender */}
                                    <div className='p-2 pt-0 pb-0 d-flex'>
                                        <div
                                            className={`p-2 w-100 ${styles.listKey} `}
                                        >
                                            Gender:{' '}
                                            {
                                                selectedRequest?.trip?.driver
                                                    ?.gender
                                            }
                                        </div>
                                    </div>
                                    {/* Email */}
                                    <div className='p-2 pt-0 pb-0 d-flex'>
                                        <div
                                            className={`p-2 w-100 ${styles.listKey} `}
                                        >
                                            Email:{' '}
                                            {
                                                selectedRequest?.trip?.driver
                                                    ?.email
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className='border mt-2 mx-2 pt-2'>
                                    <h5 className='mx-2'>Customer</h5>
                                    {/* Name */}
                                    <div className='p-2 pt-0 pb-0 d-flex'>
                                        <div
                                            className={`p-2 w-100 ${styles.listKey} `}
                                        >
                                            Name:{' '}
                                            {
                                                selectedRequest?.trip?.passenger
                                                    ?.name
                                            }
                                        </div>
                                    </div>
                                    {/* Phone */}
                                    <div className='p-2 pt-0 pb-0 d-flex'>
                                        <div
                                            className={`p-2 w-100 ${styles.listKey} `}
                                        >
                                            Phone:{' '}
                                            {
                                                selectedRequest?.trip?.passenger
                                                    ?.phone_number
                                            }
                                        </div>
                                    </div>

                                    {/* Gender */}
                                    <div className='p-2 pt-0 pb-0 d-flex'>
                                        <div
                                            className={`p-2 w-100 ${styles.listKey} `}
                                        >
                                            Gender:{' '}
                                            {
                                                selectedRequest?.trip?.passenger
                                                    ?.gender
                                            }
                                        </div>
                                    </div>
                                    {/* Email */}
                                    <div className='p-2 pt-0 pb-0 d-flex'>
                                        <div
                                            className={`p-2 w-100 ${styles.listKey} `}
                                        >
                                            Emergency Contact:{' '}
                                            {
                                                selectedRequest?.trip?.passenger
                                                    ?.emergency_contact
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {showMap && (
                            <div>
                                <MapContainer
                                    longitude={selectedRequest?.location[1]}
                                    latitude={selectedRequest?.location[0]}
                                />
                            </div>
                        )}
                    </Card.Body>
                </Card>
            )}
        </>
    );
}

export default EmergencyReportDetail;