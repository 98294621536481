import '../globals.css';

import {GeoFire} from 'geofire';
import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';

import {
    Circle,
    DirectionsRenderer,
    GoogleMap,
    Marker,
} from '@react-google-maps/api';
import {IconCaretLeft, IconMenu2, IconSquareX} from '@tabler/icons';

import {firebase} from '../firbase';

type LatLngLiteral = google.maps.LatLngLiteral;
type DirectionsResult = google.maps.DirectionsResult;
type MapOptions = google.maps.MapOptions;

type CalculatedParams = {
    distance: {text: string; value: number};
    duration: {
        text: string;
        value: number;
    };
    position: {lat: number; lng: number; driverId: string; vehicleType: string};
};

type NearestDriver = {
    lat: number;
    lng: number;
    driverId: string;
    vehicleType: string;
};
interface TruckMapProps {
    start?: any;
    end?: any;
    sendData: (data: any) => void;
    SettingConfiguration: any;
    isReset: boolean;
}
function TruckMap(props: TruckMapProps) {
    const mapRef = useRef<GoogleMap>();
    const center = useMemo<LatLngLiteral>(
        () => ({lat: 8.9806, lng: 38.7578}),
        [],
    );
    const option = useMemo<MapOptions>(
        () => ({
            mapId: 'e1fc1729f1b5dcb0',
            disableDefaultUI: false,
            clickableIcons: false,
        }),
        [],
    );

    const onLoad = useCallback((map) => (mapRef.current = map), []);
    const [startingPoint, setStartingPoint] = useState<LatLngLiteral>(
        props.start,
    );
    const [destination, setDestination] = useState<LatLngLiteral>(props.end);

    const [pickupAddress, setPickupAddress] = useState<string>();
    const [dropOfAddress, setDropOfAddress] = useState<string>();
    const [direction, setDirection] = useState<DirectionsResult>();
    const [showSearch, setShowSearch] = useState(false);
    const [places, setPlaces] = useState<NearestDriver[]>([]);
    const [vehicleType, setVehicleType] = useState<string>('taxi');
    const [calculatedPlacesDistance, setCalculatedPlacesDistance] = useState<
        CalculatedParams[]
    >([]);
    useEffect(() => {
        if (props.isReset) {
            setPlaces([]);
            setCalculatedPlacesDistance([]);
        }
    }, [props.isReset]);
    const getProviderAround = async () => {
        setPlaces([]);
        if (startingPoint) {
            var db = firebase.database().ref('availableTrucks');
            const geoFire = new GeoFire(db);
            const geoQuery = geoFire.query({
                center: [startingPoint.lat, startingPoint.lng],
                radius: props.SettingConfiguration.radius.truck, //KM
            });

            await geoQuery.on('key_entered', (key, location, distance) => {
                setPlaces((prev) => [
                    ...prev,
                    {
                        lat: location[0],
                        lng: location[1],
                        driverId: key.split(',')[0],
                        vehicleType: key.split(',')[1],
                    },
                ]);
            });
        }
    };
    useEffect(() => {
        setStartingPoint(props.start);
        setDestination(props.end);
        if (props.start && props.end) {
            const service = new google.maps.DirectionsService();
            service.route(
                {
                    origin: props.start,
                    destination: props.end,
                    travelMode: google.maps.TravelMode.DRIVING,
                },
                (result, status) => {
                    if (status === 'OK' && result) {
                        setDirection(result);
                        getProviderAround();
                    }
                },
            );
        }
    }, [props.end, props.start]);

    useEffect(() => {
        calculateDistance();
    }, [places]);

    useEffect(() => {
        if (calculatedPlacesDistance) {
            calculatedPlacesDistance.sort(
                (a, b) => a.distance.value - b.distance.value,
            );
        }

        if (calculatedPlacesDistance) {
            let driversId: string[] = [];
            for (let j = 0; j < calculatedPlacesDistance.length; j++) {
                driversId[j] = calculatedPlacesDistance[j].position?.driverId;
                if (j === 2) break;
            }

            let request: any = {
                drivers: driversId,
                pickupLocation: [props?.start?.lat, props?.start?.lng],
                droppOffLocation: [props?.end?.lat, props?.end?.lng],
            };

            props.sendData(request);
        }
    }, [calculatedPlacesDistance]);

    const calculateDistance = () => {
        if (places && startingPoint) {
            places.forEach(async (endPoint) => {
                const result = (await onCalculate(
                    startingPoint,
                    endPoint,
                )) as DirectionsResult;

                if (result) {
                    let data: CalculatedParams = {
                        distance: result.routes[0].legs[0].distance!,
                        duration: result.routes[0].legs[0].duration!,
                        position: endPoint,
                    };
                    setCalculatedPlacesDistance((prev) => [...prev, data]);
                }
            });
        }
    };
    const onCalculate = async (starPoint, endPoint): Promise<any> => {
        let response;
        if (starPoint && endPoint) {
            const service = new google.maps.DirectionsService();
            await service.route(
                {
                    origin: starPoint,
                    destination: endPoint,
                    travelMode: google.maps.TravelMode.DRIVING,
                },
                (result, status) => {
                    if (status === google.maps.DirectionsStatus.OK && result) {
                        response = result;
                    }
                },
            );
        }
        return response;
    };
    return (
        <GoogleMap
            zoom={10}
            center={center}
            mapContainerClassName='map-container'
            onLoad={onLoad}
            options={option}
        >
            {direction && <DirectionsRenderer directions={direction} />}
            {startingPoint && destination && (
                <>
                    {places.map((p, index) => (
                        <Marker
                            animation={google.maps.Animation.DROP}
                            key={index}
                            position={p}
                        />
                    ))}

                    <Circle
                        center={startingPoint}
                        radius={1500}
                        options={closeOptions}
                    />
                    <Circle
                        center={startingPoint}
                        radius={3000}
                        options={middleOptions}
                    />
                    <Circle
                        center={startingPoint}
                        radius={6000}
                        options={farOptions}
                    />
                </>
            )}
        </GoogleMap>
    );
}

export default TruckMap;

const defaultOptions = {
    strokeOpacity: 0.5,
    strokeWeight: 2,
    clickable: false,
    draggable: false,
    editable: false,
    visible: true,
};
const closeOptions = {
    ...defaultOptions,
    zIndex: 3,
    fillOpacity: 0.05,
    strokeColor: '#8BC34A',
    fillColor: '#8BC34A',
};
const middleOptions = {
    ...defaultOptions,
    zIndex: 2,
    fillOpacity: 0.05,
    strokeColor: '#FBC02D',
    fillColor: '#FBC02D',
};
const farOptions = {
    ...defaultOptions,
    zIndex: 1,
    fillOpacity: 0.05,
    strokeColor: '#FF5252',
    fillColor: '#FF5252',
};
