import { VehicleCategories } from "features/model/vehicle";
import { VehicleCategory } from "features/model/vehicle-category";
import React, { useEffect, useState } from "react";
import { Button, Card, Form } from "react-bootstrap";
import { SubmitHandler, useForm } from "react-hook-form";
import { VButton } from "shared/component/button";

import { yupResolver } from "@hookform/resolvers/yup";
import { IconDeviceFloppy, IconEdit, IconTrash } from "@tabler/icons";

import {
  useActivateVehicleCategoryMutation,
  useCreateVehicleCategoryMutation,
  useDeactivateVehicleCategoryMutation,
  useDeleteVehicleCategoryMutation,
  useUpdateVehicleCategoryMutation,
} from "../api/vehicle-category-api-services";
import { VehicleCategorySchema } from "./form-validation";

interface VehicleCategoryFormProps {
  viewMode: string;
  title: string;
  selectedItem?: any;
  selectedItemLoading?: boolean;
}
function VehicleCategoryForm(props: VehicleCategoryFormProps) {
  const [selectedItem, setSelectedItem] = useState(props.selectedItem);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<VehicleCategory>({
    resolver: yupResolver(VehicleCategorySchema),
    mode: "onBlur",
  });

  const [isCollapsed, setIsCollapsed] = useState(false);
  const [createVehicleCategory, { isLoading: creating }] =
    useCreateVehicleCategoryMutation();
  const [updateVehicleCategory, { isLoading: updating }] =
    useUpdateVehicleCategoryMutation();

  const [
    activateVehicleCategory,
    { isLoading: Activating, data: activateData, isSuccess: isSuccessActivate },
  ] = useActivateVehicleCategoryMutation();

  const [
    deactivateVehicleCategory,
    {
      isLoading: deactivating,
      data: deactivateData,
      isSuccess: isSuccessDeactivate,
    },
  ] = useDeactivateVehicleCategoryMutation();
  const [deleteVehicleCategory, { isLoading: deleting }] =
    useDeleteVehicleCategoryMutation();

  const onCreate: SubmitHandler<VehicleCategory> = (
    newData: VehicleCategory
  ) => {
    createVehicleCategory(newData);
  };

  const onUpdate: SubmitHandler<VehicleCategory> = (
    updatedData: VehicleCategory
  ) => {
    updateVehicleCategory(updatedData);
  };

  const onDelete = () => {
    deleteVehicleCategory(props.selectedItem?.id);
  };
  const onCollapsed = () => {
    var isExpand = !isCollapsed;
    setIsCollapsed(isExpand);
  };
  useEffect(() => {
    setSelectedItem(props.selectedItem);
    reset(props.selectedItem);
  }, [props.selectedItem, reset]);

  const onActivate = () => {
    activateVehicleCategory(props.selectedItem.id);
  };

  const onDeactivate = () => {
    deactivateVehicleCategory(props.selectedItem.id);
  };
  useEffect(() => {
    if (activateData && isSuccessActivate) setSelectedItem(activateData);
  }, [activateData, isSuccessActivate]);

  useEffect(() => {
    if (deactivateData && isSuccessDeactivate) setSelectedItem(deactivateData);
  }, [deactivateData, isSuccessDeactivate]);
  return (
    <>
      {props.selectedItemLoading ? (
        ""
      ) : (
        <Card>
          <Card.Header className="d-flex justify-content-between">
            <div>{props?.title}</div>
            <div className="d-flex">
              {""}
              <Button size="sm" onClick={onCollapsed}>
                {isCollapsed ? "Expand" : "Collapse"}
              </Button>
            </div>
          </Card.Header>
          <Card.Body className={isCollapsed ? "d-none" : ""}>
            <Form
              onSubmit={handleSubmit(
                props.viewMode === "new" ? onCreate : onUpdate
              )}
            >
              <div className="d-flex mb-4">
                <div className="w-50 ">
                  <Form.Group>
                    <div className="form-group required">
                      <Form.Label className="control-label"> Name</Form.Label>
                    </div>
                    <Form.Control
                      isInvalid={errors?.name ? true : false}
                      {...register("name")}
                      type="text"
                      placeholder="enter name"
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors?.name?.message}
                    </Form.Control.Feedback>
                  </Form.Group>
                </div>

                <div className="w-50 mx-4">
                  <Form.Group className="mb-3">
                    <div className="form-group required">
                      <Form.Label className="control-label"> Type</Form.Label>
                    </div>
                    <Form.Select
                      isInvalid={errors?.type ? true : false}
                      {...register("type")}
                    >
                      <option value="">select vehicle</option>
                      {VehicleCategories.map((vt) => (
                        <option value={vt}>{vt}</option>
                      ))}
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                      {errors?.type?.message}
                    </Form.Control.Feedback>
                  </Form.Group>
                </div>
              </div>

              <div className="d-flex mb-4">
                <div className="w-50 ">
                  <Form.Group>
                    <div className="form-group required">
                      <Form.Label className="control-label">
                        Initial Fare
                      </Form.Label>
                    </div>
                    <Form.Control
                      isInvalid={errors?.initial_fare ? true : false}
                      {...register("initial_fare")}
                      type="text"
                      placeholder="enter initial fare"
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors?.initial_fare?.message}
                    </Form.Control.Feedback>
                  </Form.Group>
                </div>

                <div className="w-50 mx-4">
                  <Form.Group className="mb-3">
                    <div className="form-group required">
                      <Form.Label className="control-label">
                        {" "}
                        Commission
                      </Form.Label>
                    </div>
                    <Form.Control
                      isInvalid={errors?.commission ? true : false}
                      {...register("commission")}
                      type="text"
                      placeholder="enter commission"
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors?.commission?.message}
                    </Form.Control.Feedback>
                  </Form.Group>
                </div>
              </div>

              <div className="d-flex mb-4">
                <div className="w-50 ">
                  <Form.Group>
                    <div className="form-group required">
                      <Form.Label className="control-label">
                        Discount
                      </Form.Label>
                    </div>
                    <Form.Control
                      isInvalid={errors?.discount ? true : false}
                      {...register("discount")}
                      type="text"
                      placeholder="enter discount"
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors?.discount?.message}
                    </Form.Control.Feedback>
                  </Form.Group>
                </div>

                <div className="w-50 mx-4">
                  <Form.Group className="mb-3">
                    <div className="form-group required">
                      <Form.Label className="control-label">
                        {" "}
                        Cost per Kilometer
                      </Form.Label>
                    </div>
                    <Form.Control
                      isInvalid={errors?.per_kilometer_cost ? true : false}
                      {...register("per_kilometer_cost")}
                      placeholder="enter price per kilometer"
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors?.per_kilometer_cost?.message}
                    </Form.Control.Feedback>
                  </Form.Group>
                </div>
              </div>

              <div className="d-flex mb-4">
                <div className="w-50 ">
                  <Form.Group>
                    <div className="form-group required">
                      <Form.Label className="control-label">
                        Per minute cost
                      </Form.Label>
                    </div>
                    <Form.Control
                      isInvalid={errors?.per_minute_cost ? true : false}
                      {...register("per_minute_cost")}
                      type="text"
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors?.per_minute_cost?.message}
                    </Form.Control.Feedback>
                  </Form.Group>
                </div>

                <div className="w-50 mx-4 ">
                  <Form.Group>
                    <div className="form-group required">
                      <Form.Label className="control-label">
                        Capacity
                      </Form.Label>
                    </div>
                    <Form.Control
                      type="text"
                      isInvalid={errors?.capacity ? true : false}
                      {...register("capacity")}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors?.capacity?.message}
                    </Form.Control.Feedback>
                  </Form.Group>
                </div>
              </div>

              <div className="d-flex mb-4">
                <div className="w-50 ">
                  <Form.Group>
                    <div className="form-group ">
                      <Form.Label className="control-label">
                        Description
                      </Form.Label>
                    </div>
                    <Form.Control
                      as="textarea"
                      rows={3}
                      isInvalid={errors?.description ? true : false}
                      {...register("description")}
                      type="text"
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors?.description?.message}
                    </Form.Control.Feedback>
                  </Form.Group>
                </div>
              </div>

              <div className="d-flex">
                <VButton
                  type="submit"
                  disabled={props.viewMode === "detail"}
                  variant="primary"
                  isLoading={creating}
                >
                  <IconDeviceFloppy /> Save
                </VButton>
                <VButton
                  type="submit"
                  disabled={props.viewMode === "new"}
                  className="mx-2"
                  variant="warning"
                  isLoading={updating}
                >
                  <IconEdit />
                  Update
                </VButton>
                {selectedItem ? (
                  selectedItem?.is_active ? (
                    <VButton
                      type="button"
                      onClick={onDeactivate}
                      disabled={props.viewMode === "new"}
                      variant="danger"
                      isLoading={deactivating}
                      className="mr-2"
                    >
                      {" "}
                      <IconTrash />
                      Deactivate
                    </VButton>
                  ) : (
                    <VButton
                      type="button"
                      onClick={onActivate}
                      disabled={props.viewMode === "new"}
                      variant="success"
                      isLoading={Activating}
                      className="mr-2"
                    >
                      <IconTrash />
                      Activate
                    </VButton>
                  )
                ) : (
                  ""
                )}
              </div>
            </Form>
          </Card.Body>
        </Card>
      )}
    </>
  );
}

export default VehicleCategoryForm;
