import React, { useEffect, useState } from "react";
import Header from "shared/layout/header";
import Navigation from "shared/layout/navigation";
import { useLazyListDeliveryRequestsQuery } from "../../delivery-requests/api/delivery-requests-api-service";
import { EntityConfiguration } from '../../../shared/entity/models';
import { CollectionQuery } from 'shared/model/collection.model';
import EntityList from "shared/entity/entity-list";
import {firebase } from '../../provider_tracking/map/firbase'

const OnlineProvidersList = () => {

    const [driverIds, setDriverIds] = useState([])
    let ids:any = []

    const [request, setRequest] = useState<CollectionQuery>({
        skip: 0,
        top: 10,
        orderBy: [
            {
                field: 'createdAt',
                direction: 'desc',
            },
        ],
        searchFrom: ['reciever_phone_number', 'sender_phone_number', 'tracking_id']
    });

    const [loading, setLoading] = useState(true);
    const [requests, setRequests] = useState([]);

   
    const [GetDeliveryRequests, result] = useLazyListDeliveryRequestsQuery();

    useEffect(() => {
        GetDeliveryRequests(request, false );
    }, [request])

    useEffect( () => {
                var db = firebase.database().ref('availableDrivers').once('value').then((snapshot) => {
                    const data = snapshot.val()
                    for (const id in data) {
                        if (data.hasOwnProperty(id)) {
                          
                          const value = data[id].toString().split(',')[0];
                     

                          
                        }
                      }
                });

                console.log(ids)
        },[])



    useEffect(() => {
        setRequests(result?.data?.items);
        if(result?.data?.items) {

            setLoading(false);
        }
    })

    let config: EntityConfiguration = {
        rootUrl: '/delivery-requests',
        title: 'Delivery Requests',
        // detailUrl: '/delivery-requests/delivery-detail',
        visibleColumn: [
            { key: 'sender_name', name: 'Sender name' },
            { key: 'sender_phone_number', name: 'Sender phone number' },
            { key: 'reciever_name', name: 'Reciever name' },
            { key: 'reciever_phone_number', name: 'Reciever phone number' },
            { key: 'tracking_id', name: "Tracking ID"},
            { key: 'delivery_type', name: 'Delivery type' },

            { key: 'createdAt', name: 'Created At', isDate: true },
            { key: 'status', name: 'Status' },
        ],
      
        filter: [
            [
                {
                    field: 'status',
                    value: 'created',
                    operator: '=',
                    fieldName: 'Status',
                    name: 'Created',
                },
                {
                    field: 'status',
                    value: 'confirmed',
                    operator: '=',
                    fieldName: 'Status',
                    name: 'Confirmed',
                },
                {
                    field: 'status',
                    value: 'on its way',
                    operator: '=',
                    fieldName: 'Status',
                    name: 'On its way',
                },
                {
                    field: 'status',
                    value: 'delivered',
                    operator: '=',
                    fieldName: 'Status',
                    name: 'Delivered',
                },
                {
                    field: 'status',
                    value: 'cancelled',
                    operator: '=',
                    fieldName: 'Status',
                    name: 'Cancelled',
                },
            ],
            [
                {
                    field: 'delivery_type',
                    value: 'package',
                    operator: '=',
                    fieldName: 'Delivery Type',
                    name: 'Package',
                },
                {
                    field: 'delivery_type',
                    value: 'document',
                    operator: '=',
                    fieldName: 'Delivery Type',
                    name: 'Document',
                },
                {
                    field: 'delivery_type',
                    value: 'cargo',
                    operator: '=',
                    fieldName: 'Delivery Type',
                    name: 'Cargo',
                },
                {
                    field: 'delivery_type',
                    value: 'relocation',
                    operator: '=',
                    fieldName: 'Delivery Type',
                    name: 'Relocation',
                },
               
            ],
        ],
    };



    const onSearch = (req: any) => {
        setRequest((prev) => {
            return { ...prev, search: req };
        });
    };
    const onPagination = (req: any) => {
        setRequest((prev) => {
            return { ...prev, skip: req.skip, top: req.top };
        });
    };
    const onFilter = (req: any) => {
        setRequest((prev) => {
            return {
                ...prev,
                filter: [
                    ...req,
                    [
                        {
                            field: 'vehicle',
                            value: "3453y584864845646",
                            operator: '=',
                        },
                    ],
                ],
            };
        });
    };

    return <EntityList
        itemsLoading={loading}
        pagination={onPagination}
        search={onSearch}
        items={requests}
        config={config}
        total={result?.data?.total}
        filter={onFilter}
    />
}

export default OnlineProvidersList;