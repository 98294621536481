function dateFormatter(date, type) {
    if (date) {
        const data = new Date(date);
        // Results below assume UTC timezone - your results may vary
        if (type === 'y') {
            const result = new Intl.DateTimeFormat('en-GB', {
                year: 'numeric',
            }).format(data);
            // Specify default date formatting for language (locale)
            // console.log(new Intl.DateTimeFormat('en-US').format(data))
            return result;
        } else if (type === 'm') {
            const result = new Intl.DateTimeFormat('en-GB', {
                year: 'numeric',
                month: 'short',
            }).format(data);
            // Specify default date formatting for language (locale)
            // console.log(new Intl.DateTimeFormat('en-US').format(data))
            return result;
        } else {
            const result = new Intl.DateTimeFormat('en-GB', {
                year: 'numeric',
                month: 'short',
                day: '2-digit',
            }).format(data);
            // Specify default date formatting for language (locale)
            // console.log(new Intl.DateTimeFormat('en-US').format(data))
            return result;
        }
    }
}
export {dateFormatter};
