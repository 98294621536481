import {Notification} from 'features/model/notification';
import MessagingServices from 'shared/messaging-services';
import {collectionQueryBuilder} from 'shared/model/collection-query-builder';
import {dashboardApiService} from 'store/dashboardApi';

import {render} from '@testing-library/react';

import {NotificationEndpoint} from './notification-endpoint';

const NotificationApiService = dashboardApiService.injectEndpoints({
    endpoints(builder) {
        return {
            listNotification: builder.query({
                query: (request) => ({
                    url: `${NotificationEndpoint.list}`,
                    method: 'get',
                    params: collectionQueryBuilder(request),
                }),
            }),

            detailNotification: builder.query({
                query: (id: string) => ({
                    url: `${NotificationEndpoint.detail}/${id}`,
                    method: 'get',
                }),
            }),
            send: builder.mutation({
                query: (message: Notification) => ({
                    url: `${NotificationEndpoint.send}`,
                    method: 'post',
                    data: message,
                    headers: '',
                }),
                async onQueryStarted(id, {dispatch, queryFulfilled}) {
                    try {
                        const {data} = await queryFulfilled;

                        render(
                            <MessagingServices
                                message='Notification has been sent successfully'
                                show={true}
                                type={'success'}
                            />,
                        );
                    } catch (error) {
                        render(
                            <MessagingServices
                                message='Something went wrong notification not sent'
                                show={true}
                                type={'error'}
                            />,
                        );
                    }
                },
            }),
        };
    },
});

export const {
    useLazyListNotificationQuery,
    useDetailNotificationQuery,
    useSendMutation,
} = NotificationApiService;
