import React, { useEffect } from 'react'
import { useState } from 'react'
import EntityList from 'shared/entity/entity-list'
import { EntityConfiguration } from 'shared/entity/models'
import { CollectionQuery } from 'shared/model/collection.model'
import { useLazyListCreditHistoryQuery } from '../api/public-provider-services'

function CreditHistory({ driverId }) {
  const [request, setRequest] = useState<CollectionQuery>({
    skip: 0,
    top: 10,
    filter: [
      [
        {
          field: 'driver',
          value: driverId,
          operator: '=',
        },
      ],
      [
        {
          field: 'status',
          value: 2,
          operator: '=',
        },
      ],
    ],
    searchFrom: ['payment_method', 'amount'],
    orderBy: [
      {
        field: 'createdAt',
        direction: 'desc',
      },
    ],
  })

  const [getData, result] = useLazyListCreditHistoryQuery()
  const [items, setItems] = useState(result?.data?.items)
  const [total, setTotal] = useState(result?.data?.total)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    getData(request, false)
  }, [request, getData])
  useEffect(() => {
    setItems(result?.data?.items)
    setTotal(result?.data?.total)
    setLoading(result?.isLoading)
  }, [result])

  let config: EntityConfiguration = {
    rootUrl: '/feedback',
    title: 'Transactions',
    hasDetail: false,
    detailUrl: '/feedback/detail',
    showNewButton: false,
    isTableCallapsedable: true,
    visibleColumn: [
      { key: 'created_at', name: 'Date', isDate: true },
      { key: 'payment_method', name: 'Payment Method' },
      { key: 'amount', name: 'Amount' },
    ],
  }

  const onSearch = (req: any) => {
    setRequest((prev) => {
      return { ...prev, search: req }
    })
  }
  const onPagination = (req: any) => {
    setRequest((prev) => {
      return { ...prev, skip: req.skip, top: req.top }
    })
  }
  const onFilter = (req: any) => {
    setRequest((prev) => {
      return {
        ...prev,
        filter: [
          ...req,
          [
            {
              field: 'driver',
              value: driverId,
              operator: '=',
            },
          ],
          [
            {
              field: 'status',
              value: 2,
              operator: '=',
            },
          ],
        ],
      }
    })
  }

  return (
    <EntityList
      itemsLoading={loading}
      items={items}
      total={total}
      config={config}
      search={onSearch}
      pagination={onPagination}
      filter={onFilter}
    />
  )
}

export default CreditHistory
