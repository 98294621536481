import React, {useState} from 'react';
import {Button, Form, Modal} from 'react-bootstrap';
import {SubmitHandler, useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import {schema} from './form-validation';
type CreditFormProps = {
    selectedDriverId: string;
    onSave: (credit: any) => void;
};
function CreditForm(props: CreditFormProps) {
    const [showModal, setShowModal] = useState(true);
    const {
        register,
        handleSubmit,
        formState: {errors},
    } = useForm<any>({
        resolver: yupResolver(schema),
        mode: 'onBlur',
    });
    const onAddAmount: SubmitHandler<any> = (credit: any) => {
        const sendData = {
            driver_id: props.selectedDriverId,
            amount: credit.amount,
        };
        props.onSave(sendData);
        setShowModal(false);
    };
    return (
        <>
            <Modal
                size='lg'
                centered
                show={showModal}
                onHide={() => setShowModal(false)}
            >
                <Modal.Header closeButton>
                    <Modal.Title style={{width: '500px'}}>
                        Add Credit
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body style={{width: '500px'}}>
                    <Form onSubmit={handleSubmit(onAddAmount)}>
                        <Form.Group className='mb-3' controlId='amount'>
                            <Form.Label>Amount</Form.Label>
                            <Form.Control
                                isInvalid={errors?.amount ? true : false}
                                {...register('amount')}
                                type='number'
                                placeholder='enter amount'
                            />
                            <Form.Control.Feedback type='invalid'>
                                {errors?.amount?.message}
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Button size='sm' type='submit'>
                            save
                        </Button>
                    </Form>
                </Modal.Body>
            </Modal>
        </>
    );
}
export default CreditForm;
