export enum ROLES {
    Admin = 'Admin',
    Operator = 'Operator',
    SuperAdmin = 'SuperAdmin',
    FinanceOfficer = 'FinanceOfficer',
    BankTeller = 'BankTeller',
}

export const RoleTypes = [
    'Operator',
    'FinanceOfficer',
    'Admin',
    'SuperAdmin',
    'BankTeller',
];

export interface RoleType {
    Admin: string;
    Operator: string;
    SuperAdmin: string;
    FinanceOfficer: string;
    BankTeller: string;
}
