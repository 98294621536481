import React, {useEffect, useState} from 'react';
import {Button, Card, Form} from 'react-bootstrap';
import {Outlet, useParams} from 'react-router-dom';
import EntityList from 'shared/entity/entity-list';
import {EntityConfiguration} from 'shared/entity/models';
import {CollectionQuery} from 'shared/model/collection.model';
import {CollectionQueryTwo} from 'shared/model/collection.model-two';

import {useLazyCanceledRequestQuery} from '../api/report-api-services';
import {
    CustomerCancelationReason,
    DriverCancelationReason,
} from '../models/report-model';

function CancellationReasons() {
    const [isCollapsed, setIsCollapsed] = useState(true);

    function formatDate() {
        var d = new Date(),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2) month = '0' + month;
        if (day.length < 2) day = '0' + day;

        return [year, month, day].join('-');
    }
    const [getData, result] = useLazyCanceledRequestQuery();

    const [items, setItems] = useState(result?.data?.items);
    const [total, setTotal] = useState(result?.data?.total);
    const [loading, setLoading] = useState(true);
    const [startDate, setStartDate] = useState<any>(formatDate);
    const [endDate, setEndDate] = useState<any>(formatDate);
    const [type, setType] = useState<string>('d');
    const [reason, setReason] = useState<string>();
    const [canceledBy, setCanceledBy] = useState<string>();
    const [cancelationReasonList, setCancelationReasonList] = useState<
        string[]
    >([]);

    const [filterQueryForDate, setFilterQueryForDate] = useState<any[]>([]);
    const [filterQueryForType, setFilterQueryForType] = useState<any[]>([]);
    const [filterQueryForReason, setFilterQueryForReason] = useState<any[]>([]);
    const [request, setRequest] = useState<CollectionQuery>({
        top: 10,
        skip: 0,
        format: type,
    });

    useEffect(() => {
        if (canceledBy) {
            setRequest((prev) => {
                return {
                    ...prev,
                    filter: [
                        [
                            {
                                field: 'createdAt',
                                value: [startDate, endDate],
                                operator: 'between',
                                type: 'date',
                            },
                        ],
                        [
                            {
                                field: 'cancel_reason.by',
                                value: canceledBy,
                                operator: '=',
                            },
                        ],
                    ],
                    format: type,
                };
            });
        } else {
            setRequest((prev) => {
                return {
                    ...prev,
                    filter: [
                        [
                            {
                                field: 'createdAt',
                                value: [startDate, endDate],
                                operator: 'between',
                                type: 'date',
                            },
                        ],
                    ],
                    format: type,
                };
            });
        }
    }, [canceledBy, type, startDate, endDate]);

    useEffect(() => {
        if (reason) {
            setRequest((prev) => {
                return {
                    ...prev,
                    filter: [
                        [
                            {
                                field: 'createdAt',
                                value: [startDate, endDate],
                                operator: 'between',
                                type: 'date',
                            },
                        ],
                        [
                            {
                                field: 'cancel_reason.by',
                                value: canceledBy,
                                operator: '=',
                            },
                        ],
                        [
                            {
                                field: 'cancel_reason.reason',
                                value: reason,
                                operator: '=',
                            },
                        ],
                    ],
                    format: type,
                };
            });
        } else if (canceledBy) {
            setRequest((prev) => {
                return {
                    ...prev,
                    filter: [
                        [
                            {
                                field: 'createdAt',
                                value: [startDate, endDate],
                                operator: 'between',
                                type: 'date',
                            },
                        ],
                        [
                            {
                                field: 'cancel_reason.by',
                                value: canceledBy,
                                operator: '=',
                            },
                        ],
                    ],
                    format: type,
                };
            });
        } else {
            setRequest((prev) => {
                return {
                    ...prev,
                    filter: [
                        [
                            {
                                field: 'createdAt',
                                value: [startDate, endDate],
                                operator: 'between',
                                type: 'date',
                            },
                        ],
                    ],
                    format: type,
                };
            });
        }
    }, [reason, type, startDate, endDate]);

    useEffect(() => {
        getData(request);
    }, [request, getData]);

    useEffect(() => {
        if (canceledBy === 'driver')
            setCancelationReasonList(DriverCancelationReason);
        else setCancelationReasonList(CustomerCancelationReason);
    }, [canceledBy]);

    useEffect(() => {
        setItems(result?.data?.items);
        setTotal(result?.data?.total);
        setLoading(result?.isLoading);
    }, [result]);
    let config: EntityConfiguration = {
        rootUrl: '/cancellation-reasons',
        title: 'Cancelled Request',
        detailUrl: 'detail',
        showNewButton: false,
        hasDetail: false,
        visibleColumn: [
            {key: 'date', name: 'Date'},
            {key: 'count', name: 'Requests'},
            {key: 'cancel_reason', name: 'Cancellation Reason'},
        ],
    };
    const onSearch = (req: any) => {
        setRequest((prev) => {
            return {...prev, search: req};
        });
    };
    const onPagination = (req: any) => {
        setRequest((prev) => {
            return {...prev, skip: req.skip, top: req.top};
        });
    };
    const onFilter = (req: any) => {
        setRequest((prev) => {
            return {...prev, filter: req};
        });
    };
    return (
        <Card className='mt-2'>
            <Card.Body>
                <div className='d-flex gap-4 justify-content-between items-center my-2'>
                    <div>
                        <label>Start Date</label>
                        <input
                            onChange={(event) => {
                                setStartDate(event.target.value);
                            }}
                            value={startDate}
                            style={{width: '100%', padding: '0.1rem'}}
                            type='date'
                        />
                    </div>

                    <div>
                        <label>End Date</label>
                        <input
                            onChange={(event) => {
                                setEndDate(event.target.value);
                            }}
                            value={endDate}
                            style={{width: '100%', padding: '0.1rem'}}
                            type='date'
                            min={startDate}
                        />
                    </div>

                    <div>
                        <label>Type</label>
                        <select
                            required
                            onChange={(event) => {
                                setType(event.target.value);
                                setRequest((prev) => {
                                    return {
                                        ...prev,
                                        format: event.target.value,
                                    };
                                });
                            }}
                            style={{width: '100%', padding: '0.1rem'}}
                        >
                            <option value='d'>Daily</option>
                            <option value='m'>Monthly</option>
                            <option value='y'>Yearly</option>
                        </select>
                    </div>

                    <div>
                        <label>Canceled By</label>
                        <select
                            required
                            onChange={(event) => {
                                setCanceledBy(event.target.value);
                            }}
                            style={{width: '100%', padding: '0.1rem'}}
                        >
                            <option value=''>Select Person</option>
                            <option value='driver'>Provider</option>
                            <option value='passenger'>Customer</option>
                        </select>
                    </div>

                    {canceledBy && (
                        <div>
                            <label>Reason</label>
                            <select
                                required
                                onChange={(event) => {
                                    setReason(event.target.value);
                                }}
                                style={{width: '100%', padding: '0.1rem'}}
                            >
                                <option value=''>Select reason</option>
                                {cancelationReasonList.map((da) => {
                                    return <option value={da}>{da}</option>;
                                })}
                            </select>
                        </div>
                    )}
                </div>
                <EntityList
                    itemsLoading={loading}
                    items={items || []}
                    total={total || 0}
                    config={config}
                    search={onSearch}
                    pagination={onPagination}
                    filter={onFilter}
                />
            </Card.Body>
        </Card>
    );
}
export default CancellationReasons;
