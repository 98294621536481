import {
    Vehicle,
    VehicleCategories,
    VehicleCodes,
    VehicleColors,
} from 'features/model/vehicle';
import React, {useEffect, useState} from 'react';
import {Button, Card, Form} from 'react-bootstrap';
import {SubmitHandler, useForm} from 'react-hook-form';
import {VButton} from 'shared/component/button';
import SpinnerForPages from 'shared/component/spinner-for-pages';

import {yupResolver} from '@hookform/resolvers/yup';
import {IconDeviceFloppy, IconEdit, IconTrash} from '@tabler/icons';
import {getValue} from '@testing-library/user-event/dist/utils';

import {
    useActivateVehicleMutation,
    useBlockVehicleMutation,
    useCreateVehicleMutation,
    useUpdateVehicleMutation,
} from '../api/vehicle-api-service';
import {vehicleSchema} from './from-validation';

interface VehicleFormProps {
    viewMode: string;
    title: string;
    selectedItem?: any;
    ownerId?: string;
    selectedItemLoading?: boolean;
    vehicleCategory: {
        items: any[];
        isLoading: boolean;
    };
}
function VehicleForm(props: VehicleFormProps) {
    const [isLoading, setIsLoading] = useState(props?.selectedItemLoading);
    const [isCollapsed, setIsCollapsed] = useState(false);
    const [enabled, setEnabled] = useState(props?.selectedItem?.enabled);
    const [selectedCarType, setSelectedCarType] = useState(
        props?.selectedItem?.type,
    );
    const [selectedCategory, setSelectedCategory] = useState(
        props?.selectedItem?.category,
    );
    const [addNewVehicle, {isLoading: creating}] = useCreateVehicleMutation();
    const [updateVehicle, {isLoading: updating}] = useUpdateVehicleMutation();
    const [activateVehicle, {isLoading: activating}] =
        useActivateVehicleMutation();
    const [blockVehicle, {isLoading: blocking}] = useBlockVehicleMutation();
    const onCollapsed = () => {
        var isExpand = !isCollapsed;
        setIsCollapsed(isExpand);
    };
    const [vehicleCategory, setVehicleCategory] = useState<any[]>([]);
    const {
        register,
        handleSubmit,
        reset,
        formState: {errors},
        setValue,
    } = useForm<Vehicle>({
        resolver: yupResolver(vehicleSchema),
        mode: 'onBlur',
    });

    const onCreate: SubmitHandler<Vehicle> = (newData: Vehicle) => {
        newData.owner_id = props?.ownerId ? props?.ownerId : '';
        addNewVehicle(newData);
    };

    const onUpdate: SubmitHandler<Vehicle> = (updatedData: Vehicle) => {
        updatedData.owner_id = props.selectedItem.owner_id;
        updatedData.id = props.selectedItem.id;
        updateVehicle(updatedData);
    };

    const onActivate = () => {
        activateVehicle(props?.selectedItem?.id);
        setEnabled((prevState) => !prevState);
    };
    const onBlock = () => {
        blockVehicle(props?.selectedItem?.id);
        setEnabled((prevState) => !prevState);
    };

    useEffect(() => {
        if (props.selectedItem) {
            reset(props.selectedItem);
            setSelectedCategory(props?.selectedItem?.category?.id);
            setValue('category', props?.selectedItem?.category?.id);
            setSelectedCarType(props?.selectedItem?.category?.type);
        }
        setIsLoading(props?.selectedItemLoading);
    }, [props.selectedItem, reset, props?.selectedItemLoading, setValue]);

    useEffect(() => {
        setValue('category', selectedCategory);
    }, [selectedCategory, setValue]);

    useEffect(() => {
        setEnabled(props?.selectedItem?.enabled);
    }, [props?.selectedItem?.enabled]);

    useEffect(() => {
        setVehicleCategory(
            props.vehicleCategory?.items?.filter(
                (data) => data.type === selectedCarType,
            ),
        );
    }, [props.vehicleCategory?.items, selectedCarType]);
    return (
        <>
            {isLoading ? (
                <SpinnerForPages />
            ) : (
                <Card>
                    <Card.Header className='d-flex justify-content-between'>
                        <div> {props.title}</div>
                        <div className='d-flex'>
                            <Button size='sm' onClick={onCollapsed}>
                                {isCollapsed ? 'Expand' : 'Collapse'}
                            </Button>
                        </div>
                    </Card.Header>
                    <Card.Body className={isCollapsed ? 'd-none' : ''}>
                        <Form
                            onSubmit={
                                props.viewMode === 'new'
                                    ? handleSubmit(onCreate)
                                    : handleSubmit(onUpdate)
                            }
                        >
                            <div className='d-flex mb-4'>
                                <div className='w-50'>
                                    <Form.Group>
                                        <div className='form-group required'>
                                            <Form.Label className='control-label'>
                                                Name
                                            </Form.Label>
                                        </div>
                                        <Form.Control
                                            isInvalid={
                                                errors?.model ? true : false
                                            }
                                            {...register('model')}
                                            type='text'
                                            placeholder='Enter Vehicle Name'
                                        />
                                        <Form.Control.Feedback type='invalid'>
                                            {errors?.model?.message}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </div>

                                <div className='w-50 mx-4'>
                                    <Form.Group>
                                        <div className='form-group required'>
                                            <Form.Label className='control-label'>
                                                Code
                                            </Form.Label>
                                        </div>
                                        <Form.Select
                                            isInvalid={
                                                errors?.code ? true : false
                                            }
                                            {...register('code')}
                                        >
                                            <option value=''>
                                                select code
                                            </option>
                                            {VehicleCodes.map((code) => {
                                                return (
                                                    <option
                                                        value={code}
                                                        label={code}
                                                    ></option>
                                                );
                                            })}
                                        </Form.Select>
                                        <Form.Control.Feedback type='invalid'>
                                            {errors?.code?.message}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </div>
                            </div>

                            <div className='d-flex mb-4'>
                                <div className='w-50'>
                                    <Form.Group>
                                        <div className='form-group required'>
                                            <Form.Label className='control-label'>
                                                Plate number
                                            </Form.Label>
                                        </div>
                                        <Form.Control
                                            isInvalid={
                                                errors?.plate_number
                                                    ? true
                                                    : false
                                            }
                                            {...register('plate_number')}
                                            type='text'
                                            placeholder='enter plate number'
                                        />
                                        <Form.Control.Feedback type='invalid'>
                                            {errors?.plate_number?.message}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </div>

                                <div className='w-50 mx-4'>
                                    <Form.Group>
                                        <div className='form-group required'>
                                            <Form.Label className='control-label'>
                                                Color
                                            </Form.Label>
                                        </div>
                                        <Form.Select
                                            isInvalid={
                                                errors?.color ? true : false
                                            }
                                            {...register('color')}
                                        >
                                            <option value=''>
                                                select car color
                                            </option>
                                            {VehicleColors.map((col) => {
                                                return (
                                                    <option
                                                        value={col}
                                                        label={col}
                                                    ></option>
                                                );
                                            })}
                                        </Form.Select>
                                        <Form.Control.Feedback type='invalid'>
                                            {errors?.color?.message}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </div>
                            </div>

                            <div className='d-flex mb-4'>
                                <div className='w-50'>
                                    <Form.Group>
                                        <div className='form-group required'>
                                            <Form.Label className='control-label'>
                                                Type
                                            </Form.Label>
                                        </div>
                                        <Form.Select
                                            defaultValue={
                                                props?.selectedItem?.category
                                            }
                                            isInvalid={
                                                errors?.type ? true : false
                                            }
                                            {...register('type')}
                                            onChange={(event) =>
                                                setSelectedCarType(
                                                    event.target.value,
                                                )
                                            }
                                        >
                                            <option value=''>
                                                select vehicle type
                                            </option>
                                            {VehicleCategories.map(
                                                (element) => {
                                                    return (
                                                        <option
                                                            value={element}
                                                            label={element}
                                                        ></option>
                                                    );
                                                },
                                            )}
                                        </Form.Select>
                                        <Form.Control.Feedback type='invalid'>
                                            {errors?.type?.message}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </div>

                                <div className='w-50 mx-4'>
                                    <Form.Group>
                                        <div className='form-group required'>
                                            <Form.Label className='control-label'>
                                                Vehicle Category
                                            </Form.Label>
                                        </div>
                                        <Form.Select
                                            disabled={!selectedCarType}
                                            isInvalid={
                                                errors?.category ? true : false
                                            }
                                            value={selectedCategory}
                                            {...register('category')}
                                            onChange={(e) => {
                                                setSelectedCategory(
                                                    e.target.value,
                                                );
                                            }}
                                        >
                                            <option value=''>
                                                select vehicle category
                                            </option>
                                            {vehicleCategory?.map((cat) => {
                                                return (
                                                    <option
                                                        key={cat?.id}
                                                        value={cat.id}
                                                        label={cat?.name}
                                                    ></option>
                                                );
                                            })}
                                        </Form.Select>
                                        <Form.Control.Feedback type='invalid'>
                                            {errors?.category?.message}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </div>
                            </div>

                            <div className='d-flex'>
                                <VButton
                                    type='submit'
                                    disabled={props.viewMode === 'detail'}
                                    variant='primary'
                                    isLoading={creating}
                                >
                                    <IconDeviceFloppy /> Save
                                </VButton>
                                <VButton
                                    type='submit'
                                    disabled={props.viewMode === 'new'}
                                    className='mx-2'
                                    variant='warning'
                                    isLoading={updating}
                                >
                                    <IconEdit />
                                    Update
                                </VButton>
                                {/* <VButton
                  onClick={onDelete}
                  className="mx-2"
                  disabled={props.viewMode === 'new'}
                  variant="danger"
                >
                  <IconTrash />
                  Delete
                </VButton> */}

                                {props?.selectedItem ? (
                                    enabled ? (
                                        <VButton
                                            onClick={onBlock}
                                            disabled={props.viewMode === 'new'}
                                            variant='danger'
                                        >
                                            <IconTrash />
                                            Block
                                        </VButton>
                                    ) : (
                                        <VButton
                                            onClick={onActivate}
                                            className='mx-2'
                                            disabled={props.viewMode === 'new'}
                                            variant='success'
                                        >
                                            <IconTrash />
                                            Activate
                                        </VButton>
                                    )
                                ) : (
                                    ''
                                )}
                            </div>
                        </Form>
                    </Card.Body>
                </Card>
            )}
        </>
    );
}

export default VehicleForm;
