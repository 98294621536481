import React, {useEffect, useState} from 'react';
import {Outlet, useParams} from 'react-router-dom';
import EntityList from 'shared/entity/entity-list';
import {EntityConfiguration} from 'shared/entity/models';
import {CollectionQuery} from 'shared/model/collection.model';

import {useLazyListVehicleCategoryQuery} from '../api/vehicle-category-api-services';

function VehicleCategoryList() {
    const [request, setRequest] = useState<CollectionQuery>({
        skip: 0,
        top: 10,
    });

    const [getData, result] = useLazyListVehicleCategoryQuery();
    const [items, setItems] = useState(result?.data?.items);
    const [total, setTotal] = useState(result?.data?.total);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        getData(request, false);
    }, [request, getData]);
    useEffect(() => {
        setItems(result?.data?.items);
        setTotal(result?.data?.total);
        setLoading(result?.isLoading);
    }, [result]);
    let config: EntityConfiguration = {
        rootUrl: '/settings/vehicle-category',
        title: 'Vehicle Category',
        detailUrl: 'detail',
        showNewButton: true,
        visibleColumn: [
            {key: 'name', name: 'Name'},
            {key: 'type', name: 'Type'},
            {key: 'commission', name: 'Commission'},
            {key: 'initial_fare', name: 'Initial Fare'},
            {key: 'capacity', name: 'capacity'},
            {key: 'per_kilometer_cost', name: 'Cost Per Kilometer'},
            {key: 'per_minute_cost', name: 'Cost Per Minute'},
            {key: 'is_active', name: 'Status', isBoolean: true},
        ],
        filter: [
            [
                {
                    field: 'is_active',
                    value: 'true',
                    operator: '=',
                    fieldName: 'Status',
                    name: 'Active',
                },
                {
                    field: 'is_active',
                    value: 'false',
                    operator: '=',
                    name: 'Inactive',
                    fieldName: 'Status',
                },
            ],
        ],
    };

    const onSearch = (req: any) => {
        setRequest((prev) => {
            return {...prev, search: req};
        });
    };
    const onPagination = (req: any) => {
        setRequest((prev) => {
            return {...prev, skip: req.skip, top: req.top};
        });
    };
    const onFilter = (req: any) => {
        setRequest((prev) => {
            return {...prev, filter: req};
        });
    };

    return (
        <EntityList
            itemsLoading={loading}
            items={items || []}
            total={total || 0}
            config={config}
            search={onSearch}
            pagination={onPagination}
            filter={onFilter}
        />
    );
}
export default VehicleCategoryList;
