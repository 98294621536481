import {Vehicle} from 'features/model/vehicle';
import React, {useEffect, useState} from 'react';
import {Button, Card} from 'react-bootstrap';
import EmptyState from 'shared/component/empty-state';
import SpinnerForPages from 'shared/component/spinner-for-pages';

import styles from './style.module.css';

interface PublicProviderVehicleDetailProps {
    selectedVehicle: any;
    selectedVehicleLoading: boolean;
}
function PublicProviderVehicleDetail(props: PublicProviderVehicleDetailProps) {
    const [selectedVehicle, setSelectedVehicle] = useState<Vehicle>(
        props?.selectedVehicle,
    );
    const [skip, setSkip] = useState(true);
    const [isCollapsed, setIsCollapsed] = useState(true);

    const dateFormater = (date) => {
        if (date) {
            const data = new Date(date);
            // Results below assume UTC timezone - your results may vary
            const result = new Intl.DateTimeFormat('en-GB', {
                year: 'numeric',
                month: 'long',
                day: '2-digit',
            }).format(data);
           
            return result;
        }
    };

    useEffect(() => {
        setSelectedVehicle(props.selectedVehicle);
        if (props.selectedVehicle) {
            setSkip(false);
        }
    }, [props.selectedVehicle]);

    const onCollapsed = () => {
        var isExpand = !isCollapsed;
        setIsCollapsed(isExpand);
    };

    return (
        <>
            {props.selectedVehicleLoading ? (
                <SpinnerForPages />
            ) : (
                <Card>
                    <Card.Header className='d-flex justify-content-between'>
                        <div> Vehicle Detail</div>
                        <div className='d-flex'>
                            {/* <Link to='/provider' className='text-black'><IconSquareX /></Link> */}
                            <Button size='sm' onClick={onCollapsed}>
                                {isCollapsed ? 'Expand' : 'Collapse'}
                            </Button>
                        </div>
                    </Card.Header>
                    <Card.Body className={isCollapsed ? 'd-none' : ''}>
                        {props.selectedVehicle ? (
                            <div className='border mt-2 mx-2 pt-2'>
                                {/* model */}
                                <div className='p-2 pt-0 pb-0 d-flex'>
                                    <div
                                        className={`p-2 w-25 ${styles.listKey} `}
                                    >
                                        Model
                                    </div>
                                    <div className={`p-2 w-75 ${styles.list} `}>
                                        {selectedVehicle?.model}
                                    </div>
                                </div>
                                <div className='p-2 pt-0 pb-0 d-flex'>
                                    <div
                                        className={`p-2 w-25 ${styles.listKey} `}
                                    >
                                        Plate Number
                                    </div>
                                    <div className={`p-2 w-75 ${styles.list} `}>
                                        {selectedVehicle?.plate_number}
                                    </div>
                                </div>
                                <div className='p-2 pt-0 pb-0 d-flex'>
                                    <div
                                        className={`p-2 w-25 ${styles.listKey} `}
                                    >
                                        Code
                                    </div>
                                    <div className={`p-2 w-75 ${styles.list} `}>
                                        {selectedVehicle?.code}
                                    </div>
                                </div>
                                <div className='p-2 pt-0 pb-0 d-flex'>
                                    <div
                                        className={`p-2 w-25 ${styles.listKey} `}
                                    >
                                        Type
                                    </div>
                                    <div className={`p-2 w-75 ${styles.list} `}>
                                        {selectedVehicle?.type}
                                    </div>
                                </div>
                                <div className='p-2 pt-0 pb-0 d-flex'>
                                    <div
                                        className={`p-2 w-25 ${styles.listKey} `}
                                    >
                                        Color
                                    </div>
                                    <div className={`p-2 w-75 ${styles.list} `}>
                                        {selectedVehicle?.color}
                                    </div>
                                </div>
                                <div className='p-2 pt-0 pb-0 d-flex'>
                                    <div
                                        className={`p-2 w-25 ${styles.listKey} `}
                                    >
                                        Bolo Due Date
                                    </div>
                                    <div className={`p-2 w-75 ${styles.list} `}>
                                        {dateFormater(
                                            selectedVehicle?.bolo_due_date,
                                        )}
                                    </div>
                                </div>
                                <div className='p-2 pt-0 pb-0 d-flex'>
                                    <div
                                        className={`p-2 w-25 ${styles.listKey} `}
                                    >
                                        Insurance Due Date
                                    </div>
                                    <div className={`p-2 w-75 ${styles.list} `}>
                                        {dateFormater(
                                            selectedVehicle?.insurance_certificate_due_date,
                                        )}
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <EmptyState />
                        )}
                    </Card.Body>
                </Card>
            )}
        </>
    );
}

export default PublicProviderVehicleDetail;
