export interface Owner {
    id: string;
    first_name: string;
    last_name: string;
    email: string;
    gender: string;
    phone_number: string;
    // emergencyContactNumber: string
    address: Address;
    tin: string;
}
export interface Address {
    city: string;
    sub_city: string;
    woreda: string;
    house_number: String;
}

export const GenderTypes = ['Male', 'Female'];
