export const DriverCancelationReason = [
    'Customer Asked To Cancel',
    'The Customer is unreachable',
    'Bad Pickup Location',
    'Bad Drop off Location',
    'Too Far',
    'Other',
];

export const CustomerCancelationReason = [
    'Driver Asked To Cancel',
    'Accidental Request',
    'Wrong Pickup Location',
    'Wrong Drop off Location',
    'Long Waiting Time',
    'Other',
];
