import './provider-from.scss';

import {GenderTypes} from 'features/model/owner';
import React, {useEffect, useState} from 'react';
import {Button, Card, Form, Image} from 'react-bootstrap';
import {SubmitHandler, useForm} from 'react-hook-form';
import {useParams} from 'react-router-dom';
import {VButton} from 'shared/component/button';
import SpinnerForPages from 'shared/component/spinner-for-pages';

import {yupResolver} from '@hookform/resolvers/yup';
import {IconDeviceFloppy, IconEdit, IconTrash} from '@tabler/icons';

import logo from '../../../assets/img/logo.png';
import {Provider} from '../../model/provider';
import {
    useActivateProviderMutation,
    useBlockProviderMutation,
    useCreateProviderMutation,
    useUpdateDriverPhotoMutation,
    useUpdateProviderMutation,
} from '../api/provider-api-services';
import ModalReassignDriver from '../continer/modal-reassign-driver';
import {schema} from './form-validation';
import { baseUrlForFile } from 'config/constants';

export interface ProviderFormProps {
    viewMode: string;
    title: string;
    selectedItem?: any;
    vehicleId?: any;
    selectedItemLoading?: boolean;
}
function ProviderForm(props: ProviderFormProps) {
    const {id} = useParams();
    const [imageHasError, setImageHasError] = useState<boolean>(false);
    const [isCollapsed, setIsCollapsed] = useState(false);
    const [isLoading, setIsLoading] = useState(props?.selectedItemLoading);
    const [shouldRedirect, setShouldRedirect] = useState(false);
    const [enabled, setEnabled] = useState(props?.selectedItem?.enabled);

    const [addNewProvider, {isLoading: creating}] = useCreateProviderMutation();
    const [updateProvider, {isLoading: updating}] = useUpdateProviderMutation();
    const [isOpenModal, setIsOpenedModal] = useState(false);
    const [activateProvider, {isLoading: activating}] =
        useActivateProviderMutation();
    const [blockProvider, {isLoading: blocking}] = useBlockProviderMutation();

    const [uploadProviderPhoto, {isLoading: uploading}] =
        useUpdateDriverPhotoMutation();

    const onCollapsed = () => {
        var isExpand = !isCollapsed;
        setIsCollapsed(isExpand);
        setIsOpenedModal(false);
    };

    const onActivate = () => {
        activateProvider(props.selectedItem.id);
        setEnabled((prevState) => !prevState);
        setShouldRedirect(true);
    };

    const onBlock = () => {
        blockProvider(props.selectedItem.id);
        setEnabled((prevState) => !prevState);
        setShouldRedirect(true);
    };

    useEffect(() => {
        setEnabled(props?.selectedItem?.enabled);
    }, [props?.selectedItem?.enabled]);

    const onCreate: SubmitHandler<Provider> = (newData: Provider) => {
        newData.vehicle_id = props.vehicleId ? props.vehicleId : '';
        addNewProvider(newData);
    };
    const onUpdate: SubmitHandler<Provider> = (updatedData: Provider) => {
        updatedData.vehicle_id = props.selectedItem.vehicle_id;
        updateProvider(updatedData);
    };

    const {
        control,
        register,
        handleSubmit,
        formState: {errors},
        reset,
    } = useForm<Provider>({
        resolver: yupResolver(schema),
        mode: 'onBlur',
    });
    useEffect(() => {
        if (props.selectedItem) {
            reset(props.selectedItem);
        }
        setIsLoading(props?.selectedItemLoading);
    }, [props.selectedItem, reset, props?.selectedItemLoading]);

    const onUploadProviderPhoto = (event: any) => {
        const formData = new FormData();

        if (event.target.files[0].size / 1024 <= 500) {
            formData.append('profile_image', event.target.files[0]);
            const sendData = {
                id,
                profile_image: formData,
            };
            uploadProviderPhoto(sendData);
            setImageHasError(false);
        } else {
            setImageHasError(true);
        }
    };

    const onReassign = () => {
        setIsOpenedModal(true);
    };
    const handleClose = () => {
        setIsOpenedModal(false);
    };
    return (
        <>
            {''}
            {isLoading ? (
                <SpinnerForPages />
            ) : (
                <Card>
                    <Card.Header className='d-flex justify-content-between'>
                        <div> {props.title}</div>
                        {props.selectedItem && !isCollapsed && (
                            <div className='d-flex gap-1'>
                                <Form.Control
                                    size='sm'
                                    style={{height: '30px', width: '250px'}}
                                    onChange={onUploadProviderPhoto}
                                    type='file'
                                />
                                {imageHasError && (
                                    <span style={{color: 'red'}}>
                                        max image size should be less then 500
                                        kb{' '}
                                    </span>
                                )}
                            </div>
                        )}
                        <div className='d-flex'>
                            {''}

                            {!isCollapsed && (
                                <Button
                                    size='sm'
                                    className='mx-2'
                                    onClick={onReassign}
                                >
                                    Reassign
                                </Button>
                            )}

                            <Button size='sm' onClick={onCollapsed}>
                                {isCollapsed ? 'Expand' : 'Collapse'}
                            </Button>
                        </div>
                    </Card.Header>
                    <Card.Body className={isCollapsed ? 'd-none' : ''}>
                        <Form
                            onSubmit={
                                props.viewMode === 'detail'
                                    ? handleSubmit(onUpdate)
                                    : handleSubmit(onCreate)
                            }
                        >
                            {props.selectedItem && (
                                <div className='d-flex justify-content-center align-items-center '>
                                    <div>
                                        <img
                                            style={{
                                                width: '100px',
                                                height: '100PX',
                                                borderRadius: '50%',
                                            }}
                                            src={
                                                props.selectedItem
                                                    ?.profile_image?.path
                                                    ? `${baseUrlForFile}/${props.selectedItem?.profile_image?.path}`
                                                    : logo
                                            }
                                            alt=''
                                        />
                                    </div>
                                </div>
                            )}

                            <div className='d-flex mb-4'>
                                <div className='w-50 '>
                                    <Form.Group controlId='formFirstName'>
                                        <div className='form-group required'>
                                            <Form.Label className='control-label'>
                                                First Name
                                            </Form.Label>
                                        </div>
                                        <Form.Control
                                            isInvalid={
                                                errors?.first_name
                                                    ? true
                                                    : false
                                            }
                                            {...register('first_name')}
                                            type='text'
                                            placeholder='enter first name'
                                        />
                                        <Form.Control.Feedback type='invalid'>
                                            {errors?.first_name?.message}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </div>
                                <div className='w-50 mx-4'>
                                    <Form.Group controlId='formLastName'>
                                        <div className='form-group required'>
                                            <Form.Label className='control-label'>
                                                Last Name
                                            </Form.Label>
                                        </div>
                                        <Form.Control
                                            isInvalid={
                                                errors?.last_name ? true : false
                                            }
                                            {...register('last_name')}
                                            type='text'
                                            placeholder='enter last name'
                                        />
                                        <Form.Control.Feedback type='invalid'>
                                            {errors?.last_name?.message}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </div>
                            </div>

                            <div className='d-flex mb-4'>
                                <div className='w-50 '>
                                    <Form.Group
                                        className='mb-3'
                                        controlId='formPhoneNumber'
                                    >
                                        <div className='form-group required'>
                                            <Form.Label className='control-label'>
                                                Phone number
                                            </Form.Label>
                                        </div>
                                        <Form.Control
                                            isInvalid={
                                                errors?.phone_number
                                                    ? true
                                                    : false
                                            }
                                            {...register('phone_number')}
                                            type='text'
                                            placeholder='enter phone number'
                                        />
                                        <Form.Control.Feedback type='invalid'>
                                            {errors?.phone_number?.message}
                                        </Form.Control.Feedback>
                                    </Form.Group>

                                    <Form.Group
                                        className='mb-3'
                                        controlId='formEmergencyContactNumber'
                                    >
                                        <div className='form-group required'>
                                            <Form.Label className='control-label'>
                                                Emergency contact number
                                            </Form.Label>
                                        </div>
                                        <Form.Control
                                            isInvalid={
                                                errors?.emergency_contact
                                                    ? true
                                                    : false
                                            }
                                            {...register('emergency_contact')}
                                            type='text'
                                            placeholder='enter emergence contact number'
                                        />
                                        <Form.Control.Feedback type='invalid'>
                                            {errors?.emergency_contact?.message}
                                        </Form.Control.Feedback>
                                    </Form.Group>

                                    <Form.Group
                                        className='mb-3'
                                        controlId='formEmail'
                                    >
                                        <Form.Label>Email</Form.Label>
                                        <Form.Control
                                            {...register('email')}
                                            type='text'
                                            placeholder='enter  email'
                                        />
                                    </Form.Group>

                                    <Form.Group>
                                        <div className='form-group required'>
                                            <Form.Label className='control-label'>
                                                Gender
                                            </Form.Label>
                                        </div>
                                        <Form.Select
                                            isInvalid={
                                                errors?.gender ? true : false
                                            }
                                            {...register('gender')}
                                        >
                                            <option value=''>
                                                Select gender
                                            </option>
                                            {GenderTypes.map((gender) => {
                                                return (
                                                    <option
                                                        value={gender}
                                                        label={gender}
                                                    ></option>
                                                );
                                            })}
                                        </Form.Select>
                                        <Form.Control.Feedback type='invalid'>
                                            {errors?.gender?.message}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    {''}
                                    <div className='d-flex mt-5'>
                                        <VButton
                                            type='submit'
                                            disabled={
                                                props.viewMode === 'detail'
                                            }
                                            variant='primary'
                                            isLoading={creating}
                                        >
                                            {' '}
                                            <IconDeviceFloppy /> Save
                                        </VButton>
                                        <VButton
                                            type='submit'
                                            disabled={props.viewMode === 'new'}
                                            className='mx-2'
                                            variant='warning'
                                            isLoading={updating}
                                        >
                                            <IconEdit />
                                            Update
                                        </VButton>

                                        {props?.selectedItem ? (
                                            enabled ? (
                                                <VButton
                                                    onClick={onBlock}
                                                    disabled={
                                                        props.viewMode === 'new'
                                                    }
                                                    variant='danger'
                                                    isLoading={blocking}
                                                    className='mr-2'
                                                >
                                                    {' '}
                                                    <IconTrash />
                                                    Block
                                                </VButton>
                                            ) : (
                                                <VButton
                                                    onClick={onActivate}
                                                    disabled={
                                                        props.viewMode === 'new'
                                                    }
                                                    variant='success'
                                                    isLoading={activating}
                                                    className='mr-2'
                                                >
                                                    <IconTrash />
                                                    Activate
                                                </VButton>
                                            )
                                        ) : (
                                            ''
                                        )}
                                    </div>
                                </div>

                                <div className='w-50 mx-4'>
                                    <Card>
                                        <Card.Header>Address</Card.Header>
                                        <Card.Body>
                                            <Form.Group
                                                className='mb-2'
                                                controlId='formCity'
                                            >
                                                <div className='form-group required'>
                                                    <Form.Label className='control-label'>
                                                        City
                                                    </Form.Label>
                                                </div>
                                                <Form.Control
                                                    isInvalid={
                                                        errors?.address?.city
                                                            ? true
                                                            : false
                                                    }
                                                    {...register(
                                                        'address.city',
                                                    )}
                                                    type='text'
                                                    placeholder='enter city'
                                                />
                                                <Form.Control.Feedback type='invalid'>
                                                    {
                                                        errors?.address?.city
                                                            ?.message
                                                    }
                                                </Form.Control.Feedback>
                                            </Form.Group>

                                            <Form.Group
                                                className='mb-2'
                                                controlId='formSubCity'
                                            >
                                                <div className='form-group required'>
                                                    <Form.Label className='control-label'>
                                                        SubCity
                                                    </Form.Label>
                                                </div>
                                                <Form.Control
                                                    isInvalid={
                                                        errors?.address
                                                            ?.sub_city
                                                            ? true
                                                            : false
                                                    }
                                                    {...register(
                                                        'address.sub_city',
                                                    )}
                                                    type='text'
                                                    placeholder='enter SubCity'
                                                />
                                                <Form.Control.Feedback type='invalid'>
                                                    {
                                                        errors?.address
                                                            ?.sub_city?.message
                                                    }
                                                </Form.Control.Feedback>
                                            </Form.Group>

                                            <Form.Group
                                                className='mb-2'
                                                controlId='formWoreda'
                                            >
                                                <div className='form-group required'>
                                                    <Form.Label className='control-label'>
                                                        Woreda
                                                    </Form.Label>
                                                </div>
                                                <Form.Control
                                                    isInvalid={
                                                        errors?.address?.woreda
                                                            ? true
                                                            : false
                                                    }
                                                    {...register(
                                                        'address.woreda',
                                                    )}
                                                    type='text'
                                                    placeholder='enter woreda'
                                                />
                                                <Form.Control.Feedback type='invalid'>
                                                    {
                                                        errors?.address?.woreda
                                                            ?.message
                                                    }
                                                </Form.Control.Feedback>
                                            </Form.Group>

                                            <Form.Group controlId='formHouseNumber'>
                                                <div className='form-group required'>
                                                    <Form.Label className='control-label'>
                                                        House Number
                                                    </Form.Label>
                                                </div>
                                                <Form.Control
                                                    isInvalid={
                                                        errors?.address
                                                            ?.house_number
                                                            ? true
                                                            : false
                                                    }
                                                    {...register(
                                                        'address.house_number',
                                                    )}
                                                    type='text'
                                                    placeholder='enter house number'
                                                />
                                                <Form.Control.Feedback type='invalid'>
                                                    {
                                                        errors?.address
                                                            ?.house_number
                                                            ?.message
                                                    }
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </Card.Body>
                                    </Card>
                                </div>
                            </div>
                        </Form>
                    </Card.Body>
                </Card>
            )}
            {isOpenModal && (
                <ModalReassignDriver
                    showModal={isOpenModal}
                    handleClose={handleClose}
                    driver={props.selectedItem}
                />
            )}
        </>
    );
}

export default ProviderForm;
