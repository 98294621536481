import React, {useEffect, useState} from 'react';
import {Button, Card, Modal} from 'react-bootstrap';
import {useParams} from 'react-router-dom';
import EntityList from 'shared/entity/entity-list';
import {EntityConfiguration} from 'shared/entity/models';
import {CollectionQuery} from 'shared/model/collection.model';
import {IconPlus} from '@tabler/icons';
import {render} from '@testing-library/react';
import {
    useAddAwardItemMutation,
    useLazyListAwardItemsQuery,
    useRemoveItemMutation,
} from '../api/award-api-service';
import AwardItemForm from '../component/award-item-form';
function AwardItemList() {
    const {id} = useParams();
    const [request, setRequest] = useState<CollectionQuery>({
        skip: 0,
        top: 10,
        filter: [
            [
                {
                    field: 'award_type',
                    value: id,
                    operator: '=',
                },
            ],
        ],
    });
    const [getData, result] = useLazyListAwardItemsQuery();
    const [items, setItems] = useState(result?.data?.items);
    const [total, setTotal] = useState(result?.data?.total);
    const [loading, setLoading] = useState(true);
    const [isCollapsed, setIsCollapsed] = useState(true);
    const [deleteItem] = useRemoveItemMutation();
    const onCollapsed = () => {
        var isExpand = !isCollapsed;
        setIsCollapsed(isExpand);
    };
    useEffect(() => {
        getData(request, false);
    }, [request, getData]);
    useEffect(() => {
        setItems(result?.data?.items);
        setTotal(result?.data?.total);
        setLoading(result?.isLoading);
    }, [result]);
    let config: EntityConfiguration = {
        rootUrl: '/award',
        title: 'awards Items List',
        detailUrl: '/award',
        showNewButton: false,
        hasDetail: false,
        visibleColumn: [
            {key: 'title', name: 'Title'},
            {key: 'amount', name: 'Amount'},
            {key: 'created_at', name: 'Registered At', isDate: true},
        ],
        filter: [
            [
                {
                    field: 'status',
                    value: 'Active',
                    operator: '=',
                    fieldName: 'Status',
                },
                {
                    field: 'status',
                    value: 'InActive',
                    operator: '=',
                    fieldName: 'Status',
                },
            ],
            [
                {
                    field: 'category',
                    value: 'New',
                    operator: '=',
                    fieldName: 'Category',
                },
                {
                    field: 'category',
                    value: 'Old',
                    operator: '=',
                    fieldName: 'Category',
                },
            ],
        ],
    };
    const onSearch = (req: any) => {
        setRequest((prev) => {
            return {...prev, search: req};
        });
    };
    const onPagination = (req: any) => {
        setRequest((prev) => {
            return {...prev, skip: req.skip, top: req.top};
        });
    };
    const onFilter = (req: any) => {
        if (req) {
            setRequest((prev) => {
                return {
                    ...prev,
                    filter: [
                        ...req,
                        [
                            {
                                field: 'award_type',
                                value: id,
                                operator: '=',
                            },
                        ],
                    ],
                };
            });
        }
    };
    const [add] = useAddAwardItemMutation();
    const onOpenModal = () => {
        render(
            <AwardItemForm
                selectedAwardId={id!}
                onSave={async (item: any) => {
                    await add(item);
                    //   refetch()
                }}
            />,
        );
    };
    const onDelete = (item) => {
        deleteItem(item.id);
    };
    return (
        <>
            <Card className='my-4'>
                <Card.Header className='d-flex justify-content-between'>
                    <div>Award Items</div>
                    <div className='d-flex gap-2'>
                        {!isCollapsed && (
                            <Button size='sm' onClick={onOpenModal}>
                                <IconPlus /> Add
                            </Button>
                        )}
                        <Button size='sm' onClick={onCollapsed}>
                            {isCollapsed ? 'Expand' : 'Collapse'}
                        </Button>
                    </div>
                </Card.Header>
                <Card.Body className={isCollapsed ? 'd-none' : ''}>
                    <EntityList
                        itemsLoading={loading}
                        items={items}
                        total={total}
                        config={config}
                        search={onSearch}
                        pagination={onPagination}
                        filter={onFilter}
                        delete={onDelete}
                    />
                </Card.Body>
            </Card>
        </>
    );
}
export default AwardItemList;
