import {collectionQueryBuilder} from 'shared/model/collection-query-builder';
import {dashboardApiService} from 'store/dashboardApi';

import {dashboardEndpoint} from './dashboard-endpoint';

const CollectionTypeApiService = dashboardApiService.injectEndpoints({
    endpoints(builder) {
        return {
            getTotalTodayPassengers: builder.query({
                query: (query: any) => ({
                    url: `${dashboardEndpoint.getTotalNewPassengers}`,
                    method: 'get',
                    params: collectionQueryBuilder(query),
                }),
            }),
            getTotalDriver: builder.query({
                query: () => ({
                    url: `${dashboardEndpoint.getTotalDriver}?groupBy=is_active`,
                    method: 'get',
                }),
            }),
            getNewIncome: builder.query({
                query: (query) => ({
                    url: `${dashboardEndpoint.getTotalIncome}`,
                    method: 'get',
                    params: collectionQueryBuilder(query),
                }),
            }),
            getTotalPassengers: builder.query({
                query: () => ({
                    url: `${dashboardEndpoint.getTotalPassengers}`,
                    method: 'get',
                }),
            }),
            getTotalIncome: builder.query({
                query: () => ({
                    url: `${dashboardEndpoint.getTotalIncome}`,
                    method: 'get',
                }),
            }),
            getIncomeByVehicleType: builder.query({
                query: () => ({
                    url: `${dashboardEndpoint.getIncomeByVehicleType}`,
                    method: 'get',
                }),
            }),
            getTotalTransaction: builder.query({
                query: () => ({
                    url: `${dashboardEndpoint.getTotalTransaction}`,
                    method: 'get',
                }),
            }),
            getAllDriversTotalCredit: builder.query({
                query: () => ({
                    url: `${dashboardEndpoint.total_drivers_credit}`,
                    method: 'get',
                }),
            }),
        };
    },
});

export const {
    useGetNewIncomeQuery,
    useGetTotalDriverQuery,
    useGetTotalIncomeQuery,
    useGetTotalTodayPassengersQuery,
    useGetTotalPassengersQuery,
    useGetIncomeByVehicleTypeQuery,
    useGetTotalTransactionQuery,
    useLazyGetAllDriversTotalCreditQuery,
} = CollectionTypeApiService;
